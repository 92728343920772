import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { settingsLoaded, settingsSaved, settingsLogoSelected, cropHasBeenSet, croppedImageSet, cropImageUploaded } from "../../actions/index"
import ReactCrop from 'react-image-crop';


class CropImageUpload extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {

    }

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
          //  this.props.croppedImageSet(croppedImageUrl);
        }
    }

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.props.cropHasBeenSet(crop);
    };
    // Render function of any of your components:
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.blob = blob;
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }
    uploadCropImage = () => {


        this.props.cropImageUploaded(this.blob);


    }


    render = () => {

        if (this.props.showModal) {
            const cropType = () => {


                return (this.props.circularCrop)

            }

            return (
                <div id="overlay">
                    <div className="popup">

                        <label htmlFor="backgroundColor">Crop Image</label>

                        <ReactCrop
                            src={this.props.src_img}
                            crop={this.props.crop}
                            onImageLoaded={this.onImageLoaded}
                            ruleOfThirds
                            circularCrop={cropType()}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}

                        />
                        <button className="btn btn-sm btn-primary pull-left" type="button" onClick={this.uploadCropImage}>Save</button>
                    </div>
                </div>
            )
        }
        return "";
    }
    
   
}

function mapStateToProps(state) {
    return {
        showModal: state.settings.showModal,
       // cardtemplate: state.settings.cardtemplate,
        src_img: state.settings.src_img,
        crop: state.settings.crop,
        croppedImageUrl: state.settings.croppedImageUrl,
        circularCrop: state.settings.circularCrop
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({

        settingsLogoSelected: settingsLogoSelected,
        cropHasBeenSet: cropHasBeenSet,
        croppedImageSet: croppedImageSet,
        cropImageUploaded: cropImageUploaded

        // callMessage: callMessage 
    }, dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(CropImageUpload);