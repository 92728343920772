import React, { Component } from "react";
import MenuItem from "./menu-item"

export default class MenuItemList extends Component {
    render() {
        const path = this.props.path;
        return (
            <ul className={this.props.cssClass}>
                <MenuItem text="Home" cssClass={path === "/web" ? "nav-item active" : ""} url="/web" />
                <MenuItem text="Dashboard" cssClass={path === "/web/cards" ? "nav-item  active" : ""} url="/web/cards" />
                <MenuItem text="Print" cssClass={path === "/web/print" ? "nav-item active" : ""} url="/web/print" />
                <MenuItem text="Scan" cssClass={path === "/web/scan" ? "nav-item active" : ""} url="/web/scan" />
                <MenuItem text="Settings" cssClass={path === "/web/settings" ? "nav-item active" : ""} url="/web/settings" />
            </ul>
        )
    }
}