import React, { Component } from 'react';
import Logo from '../../assets/caffee_logo.png';
import Star from '../../assets/star.svg';
import Abhichek from '../../assets/Abhishek.jpeg';

export default class TestemonialCard extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        detailsVisible: false
    };

    toggleVisible = () => {
        this.setState({ detailsVisible: !this.state.detailsVisible });
    };

    render() {
        return (
            <div className="mb-4 loremCard white_box p-4">
                <div className="text-start">
                    <img src={Star} alt="icon" />
                    <img src={Star} alt="icon" />
                    <img src={Star} alt="icon" />
                    <img src={Star} alt="icon" />
                    <img src={Star} alt="icon" />
                </div>
                <div>
                    <p className="text-start mt-4 fs-6">
                        {this.props.description}
                    </p>

                    <div className="row align-items-center mt-4">
                        {/* Red za sliku i ime korisnika */}
                        <div className="col-12 col-md-8 d-flex align-items-center">
                            <div className="col-3">
                                <img
                                    className="rounded-circle img-fluid"
                                    src={Abhichek}
                                    alt="Photo"
                                />
                            </div>
                            <div className="col-9">
                                <h5 className="mb-0">Pera Peric</h5>
                                <p className="fs-6 mb-0">Manager, Awesome Bar</p>
                            </div>
                        </div>

                        {/* Red za logo firme */}
                        <div className="col-12 col-md-4 text-center mt-3 mt-md-0">
                            <img className="img-fluid" src={Logo} alt="Logo" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
