import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { signup, signUpReset } from "../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import './style.css'
import StampedoLogo from '../assets/gpt_logo.png';
import "bootstrap-icons/font/bootstrap-icons.css";
import { renderPasswordField } from "./login_form";
const validate = values => {
    const errors = {}
    if (!values.Password) {
        errors.Password = 'Required'
    }
    if (!values.Email) {
        errors.Email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
        errors.Email = 'Invalid email address'
    }
    if (!values.Name) {
        errors.Name = 'Required'
    }
    if (values.Password != values.RepeatPassword) {
        errors.RepeatPassword = "Passwords do not match";
    }
    
    return errors
}
const renderField = ({ input, label, type, className, meta: { touched, error, warning } }) => (
    <div >
        <input className="form-control p-2 login_field" {...input} placeholder={label} type={type} />
        {touched && ((error && <span className="form-text text-danger">{error}</span>)
            || (warning && <span>{warning}</span>))
            || (!warning && !error) && <div className="form-text text-danger mb-4"> </div>
            || !touched && <div className="form-text text-danger mb-4"> </div>}
    </div>
)

class SignupForm extends Component {
    constructor(props) {
        super(props);

        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.state = {
            blocking: false,
        };
    }

    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
   
    
    addLocalStoregeToken(props) {
        var tokenData = {
            accessToken: props.accessToken,
            message: props.message
        }
        var now = new Date().getTime();
        localStorage.removeItem("setupTime")
        localStorage.setItem('setupTime', now);
        localStorage.setItem('accessToken', JSON.stringify(tokenData))
    }
    componentWillMount() {
      
    }

    componentWillReceiveProps(nextProps, message) {
        console.log("signup componentwillreceiveprops");
        var status = this.props.status;
        var newstatus = nextProps.status    ;
        if (newstatus !== 0) {
            if (newstatus === 200) {
               this.context.router.history.push("/public/thankyou");
               this.props.signUpReset()
                }
        }
        //else {
         //       this.toggleBlocking();
          //      alert("Test: " + nextProps.message);
          //  }

        }
    

    onSubmit(props) {
        this.toggleBlocking();
       // console.log("pritisnut je submit");
      this.props.signup(props);

    }
    renderOld() {
        const { handleSubmit, submitting, invalid  } = this.props
        return (
            <div className="col-md-12 col-sm-12">
                <BlockUi tag="div" blocking={this.state.blocking}>
                    <div className="container">
                        <form className="form-signin" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                            <h2 className="form-signin-heading">Please sign up</h2>
                            <Field name="Name" type="string" className="form-control" component={renderField} label="Name" />
                            <Field name="Email" type="email" className="form-control" component={renderField} label="Email" />
                            <Field name="Password" type="password" className="form-control" component={renderField} label="Password" />
                            <Field name="RepeatPassword" type="password" className="form-control" component={renderField} label="Repeat Password" />
                            <div>
                                <button type="submit" className="btn btn-primary" disabled={submitting || invalid }>Submit</button>
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                {this.props.signUpDone}
                            <br />
                                Password : 123456
                        </div>
                        </form>
                    </div>
                </BlockUi>
            </div>
        );
    }

    toggleChecked = () => {
        this.setState({ keepMeLoggedIn: !this.state.keepMeLoggedIn });
    };
    render() {
        const { handleSubmit, submitting } = this.props
        return (
            <div  style={{
                height: "100%", width: "100%", background: '#F1F1F1',
                position: "absolute",
                top: 0,
                left: 0
            }}>
                
                <BlockUi tag="div" blocking={this.state.blocking}>
                    <div className="container" style={{ height: "100%", width: "100%", background: '#F1F1F1' }}>

                        <div className="row justify-content-center" >
                            <div className="col-md-4 col-lg-4 p-4 box mt-5 mb-5 me-4 "
                                style={{
                                    borderRadius: '8px', background: '#FFFFFF'
                                }}>

                                <div className="text-left mb-3" style={{ color: '#31303D', fontSize: 22, fontFamily: 'Space Grotesk', fontWeight: '700', lineHeight: "28.80px", wordWrap: 'break-word' }}>Select Subscription Plan </div>
                                <div className="text-left mb-3" style={{ width: '100%', color: '#31303D', fontSize: 14, fontFamily: 'Poppins', fontWeight: '300', lineHeight: "20px", wordWrap: 'break-word' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. dolor sit amet eiusmod tempor incididunt </div>
                                <div className = "mb-4"style={{  background: '#F8FAFC', borderRadius: 8, border: '2px #E8C400 solid' }}>
                                    <div className=" d-flex  justify-content-between p-2">
                                     <div style={{ color: '#31303D', fontSize: 16, fontFamily: 'Space Grotesk', fontWeight: '700', textTransform: 'uppercase', lineHeight: "28.80px", wordWrap: 'break-word' }}>Starter plan</div>
                                    <div style={{alignItems: 'center', gap: 16, display: 'inline-flex' }}>
                                        <div style={{ color: '#31303D', fontSize: 16, fontFamily: 'Poppins', fontWeight: '700', lineHeight: "28.80px", wordWrap: 'break-word' }}>$9.99</div>
                                        <div style={{ width: 18, height: 18, position: 'relative' }}>
                                            <div style={{ width: 18, height: 18, left: 0, top: 0, position: 'absolute', background: 'white', borderRadius: 9999, border: '1.50px #C4C4C4 solid' }} />
                                            <div style={{ width: 10, height: 10, left: 4, top: 4, position: 'absolute', background: '#E8C400', borderRadius: 9999 }} />
                                            </div>
                                    </div>
                                    </div>
                                    <div className = "text-left mb-2" style={{ color: '#31303D', fontSize: 14, fontFamily: 'Poppins', fontWeight: '500', textDecoration: 'underline', lineHeight: "16px", wordWrap: 'break-word' }}>Show Details</div>
                                </div>
                                <div style={{ background: '#F8FAFC', borderRadius: 8, border: '2px #E8C400 solid' }}>
                                    <div className=" d-flex  justify-content-between p-2">
                                        <div style={{ color: '#31303D', fontSize: 16, fontFamily: 'Space Grotesk', fontWeight: '700', textTransform: 'uppercase', lineHeight: "28.80px", wordWrap: 'break-word' }}>Premium Plan</div>
                                        <div style={{ alignItems: 'center', gap: 16, display: 'inline-flex' }}>
                                            <div style={{ color: '#31303D', fontSize: 16, fontFamily: 'Poppins', fontWeight: '700', lineHeight: "28.80px", wordWrap: 'break-word' }}>$19.99</div>
                                            <div style={{ width: 18, height: 18, position: 'relative' }}>
                                                <div style={{ width: 18, height: 18, left: 0, top: 0, position: 'absolute', background: 'white', borderRadius: 9999, border: '1.50px #C4C4C4 solid' }} />
                                                <div style={{ width: 10, height: 10, left: 4, top: 4, position: 'absolute', background: '#E8C400', borderRadius: 9999 }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-left mb-2" style={{ color: '#31303D', fontSize: 14, fontFamily: 'Poppins', fontWeight: '500', textDecoration: 'underline', lineHeight: "16px", wordWrap: 'break-word' }}>Show Details</div>
                                    <div style={{ width: '100%', height: '100%', padding: 10, background: '#F8FAFC', borderRadius: 4, justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
                                        <div style={{ width: 378, color: '#31303D', fontSize: 13, fontFamily: 'Poppins', fontWeight: '300', lineHeight: "18px", wordWrap: 'break-word' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. dolor sit amet eiusmod tempor incididunt </div>
                                    </div>
                                    <div style={{ width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex' }}>
                                        <div style={{ width: 231, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
                                            <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                                <div style={{ color: '#31303D', fontSize: 14, fontFamily: 'Poppins', fontWeight: '700', lineHeight: "21px", wordWrap: 'break-word' }}>6 months free trial</div>
                                            </div>
                                        </div>
                                        <div style={{ width: 118, height: 29, justifyContent: 'flex-end', alignItems: 'center', gap: 16, display: 'flex' }}>
                                            <div style={{ width: 20, height: 20, position: 'relative' }}>
                                            
                                                <i className={`bi bi-check`}

                                                    style={{
                                                        "background-color": "transparent",
                                                        border: "none",
                                                        height: "30px",
                                                        color: "#0FBA3F",
                                                        fontSize: 24,
                                                        display: 'flex'
                                                       
                                                    }} >

                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-4 col-lg-4 p-4 box mt-5 mb-5"
                                style={{
                                    borderRadius: '8px', background: '#FFFFFF'
                                }}>

                                <div className="text-center mb-2">
                                    <img src={StampedoLogo} alt="Stampedo Logo" style={{ width: '100px', height: 'auto' }} />
                                </div>
                                <div className="text-center mb-3" style={{ fontSize: 24, color: '#222222', fontFamily: 'Poppins', fontWeight: 600, lineHeight: '28.80px' }}>Sign up</div>
                                <div className="text-center mb-2" style={{ fontSize: 15, color: '#7D7D7D', fontFamily: 'Poppins', fontWeight: 400, lineHeight: '26.56px' }}>Enter your credentials to continue</div>
                                <div className="text-center mb-4" style={{ fontSize: 15, color: '#31303D', fontFamily: 'Poppins', fontWeight: 400, lineHeight: '26.56px' }}>Sign up with Email address</div>
                                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                    <div className="d-flex justify-content-between">
                                    <div className = "me-3">
                                    <Field  name="Name" type="string" component={renderField} label="First Name" /> </div>
                                        <Field name="Surname" type="string" component={renderField} label="Last Name" />
                                    </div>
                                    <Field name="Email" type="email" component={renderField} label="Email Address / Username" />
                                    <Field name="Password" type="password" component={renderPasswordField} label="Password" />
                                  


                                    <div className="d-flex justify-content-md-between  mb-4">
                                        <div className="  align-content-center  d-flex">

                                            <i
                                                className={`me-1 bi bi-${this.state.keepMeLoggedIn ? "check-square-fill " : "square"}`}
                                                style={{
                                                    backgroundColor: "transparent",
                                                    border: "none",
                                                    color: "black",
                                                    cursor: "pointer"
                                                }}
                                                onClick={this.toggleChecked.bind(this)}
                                            >
                                            </i>


                                            <div className="ml-2" style={{ color: '#364152', fontSize: 13, fontFamily: 'Poppins' }}>Agree with Terms & Conditions</div>
                                        </div>
                                       

                                    </div>

                                    <button className="form-control btn-signin mb-4" type="submit"
                                        style={{ color: '#31303D', fontSize: 16, fontFamily: 'Space Grotesk', textTransform: 'uppercase', background: '#FFD800', borderRadius: 300, height: 42 }}
                                        disabled={submitting}
                                    >Sign In</button>

                                </form>
                                <div className="mb-2" style={{ width: '100%', height: '1px', background: '#E3E8EF' }} />
                                <div className="text-center" style={{ color: '#121926', fontSize: 14, fontFamily: 'Poppins' }}>Already have an account?</div>
                            </div>
                        </div>





                    </div>
                </BlockUi>
            </div>

        );
    }







}

function mapStateToProps(state) {
    return {
        signUpDone: state.accessToken.signUpDone,
      //  message: state.accessToken.message,
        status: state.accessToken.status,
      //  statusClass: state.accessToken.statusClass
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        signup: signup,
        signUpReset: signUpReset


    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'signupForm',
    validate
})(SignupForm));

SignupForm.contextTypes = {
    router: PropTypes.object
}
