import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { login, logout, togglePasswordVisible } from "../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import './style.css'
import StampedoLogo from '../assets/gpt_logo.png';
import Tick from '../assets/tick.svg';
import "bootstrap-icons/font/bootstrap-icons.css";

const validate = values => {
    const errors = {}
    if (!values.Password) {
        errors.Password = 'Required'
    }
    if (!values.Email) {
        errors.Email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
        errors.Email = 'Invalid email address'
    }
    return errors
}
const renderField = ({ input, label, type, className, meta: { touched, error, warning } }) => (
    <div >
        <input className="form-control p-2 login_field" {...input} placeholder={label} type={type}  />
        {touched && ((error && <span className="form-text text-danger">{error}</span>)
            || (warning && <span>{warning}</span>))
            || (!warning && !error) && <div className="form-text text-danger mb-4"> </div>
            || !touched && <div className="form-text text-danger mb-4"> </div>}
    </div>
)

export class renderPasswordField extends Component {
    constructor(props) {
        super(props);

    }
   // ({ input, label, type, className, meta: { touched, error, warning } }) => {
   state = {
        passwordVisible: false
    };
   toggleVisible = () => {
         this.setState({ passwordVisible: !this.state.passwordVisible });
   };
    render() {
        
        
        const { touched, error, warning }  = this.props.meta;
            return (
                <div  >
                    <div className="form-group input-container login_field">
                        <div className="d-flex justify-content-between align-items-center"  >
                            <input className="p-2 form-control "  {...this.props.input}

                                placeholder="Password"
                                type={this.state.passwordVisible ? "text" : "password"}
                                style={{
                                    "background-color": "transparent",
                                    border: "none"

                                }}
                            >

                            </input>
                            <i className={`bi bi-eye${this.state.passwordVisible ? "-slash" : ""}-fill p-2`}
                               
                                style={{
                                "background-color": "transparent",
                                border: "none",
                                color: "black",
                                cursor: "pointer"
                            }} onClick={this.toggleVisible.bind(this)}>

                            </i>
                        </div>

                    </div>
                    {touched && ((error && <span className="form-text text-danger">{error}</span>)
                        || (warning && <span>{warning}</span>))
                        || (!warning && !error) && <div className="form-text text-danger mb-4"> </div>
                        || !touched && <div className="form-text text-danger mb-4"> </div>}
                </div>

            )
        }
} 

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.state = {
            blocking: false,
            keepMeLoggedIn: false
        }   ;
    }
   

    password_type = "password";
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    getAccessToken() {
        this.expireTimeControl();
        var accessToken = localStorage.getItem('accessToken');
        return accessToken;
    }
    expireTimeControl() {
        var hours = 10; // Reset when storage is more than 10 hours
        var now = new Date().getTime();
        var setupTime = localStorage.getItem('setupTime');

        if (now - setupTime > hours * 60 * 60 * 1000) {
            this.props.logout();
        }
    }
    addLocalStorageToken(props) {
        var tokenData = {
            accessToken: props.accessToken,
            message: props.message
        }
        var now = new Date().getTime();
        localStorage.removeItem("setupTime")
        localStorage.setItem('setupTime', now);
        localStorage.setItem('accessToken', JSON.stringify(tokenData))
    }


    componentWillMount() {
        console.log("login componentwillmount");
        var accessToken = this.getAccessToken();
      
        if (accessToken != null) {
      //      this.props.login(null);
        }
    }

    componentWillReceiveProps(nextProps, message) {
        console.log("login componentwillreceiveprops");
        var status = this.props.status;
        var newstatus = nextProps.status;
       
        if (newstatus !== 0) {
            if (newstatus === 200) {
                if (nextProps.accessToken !== undefined && nextProps.accessToken !== "") {
                    this.toggleBlocking();
                    this.addLocalStorageToken(nextProps);
                  
                    if (this.props.location.state && this.props.location.state.fromPath) {
                        console.log(this.props.location.state.fromPath);
                        this.context.router.history.push(this.props.location.state.fromPath);
                    }
                    else {
                        this.context.router.history.push("/admin");
                    }
                }
            } else {
                this.toggleBlocking();
                alert("Test: " + nextProps.message);
            }

        }
    }
    renderOldform() {


        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
            <div className="container">
                <form className="form-signin" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <h2 className="form-signin-heading">Please sign in</h2>
                    <Field name="Email" type="email" className="form-control" component={renderField} label="Email" />
                    <Field name="Password" type="password" className="form-control" component={renderField} label="Password" />
                    <div>
                        <button type="submit" className="btn btn-primary" disabled={submitting}>Submit</button>
                    </div>

                </form>
            </div>
        </BlockUi>
)
    }

    onSubmit(props) {
      
        this.toggleBlocking();
        this.props.login(props);
    }

    toggleChecked = () => {
        this.setState({ keepMeLoggedIn: !this.state.keepMeLoggedIn });
    };

    render() {
        const { handleSubmit, submitting } = this.props
        return (
            <div style={{
                height: "100%", width: "100%", background: '#F1F1F1',
                 position: "absolute",
                top: 0,
                left: 0
            }}>
                <div className="row  justify-content-center">
                    <div className="col-md-6  mt-4" style={{
                        background: 'rgba(15.49, 185.94, 63.22, 0.10)',
                        textAlign: 'center', color: '#08902E',
                        wordWrap: 'break-word',
                        fontSize: '16px',
                        fontFamily: 'Poppins',
                        fontWeight: '500',
                        borderRadius: '8px',
                        padding: "20px"
                    }}>
                        Congratulations! Your email address has been successfully verified. <br />You  can now log in to our website and start using our services.
                    </div>
                </div>

            <BlockUi tag="div" blocking={this.state.blocking}>
            <div className="container" style={{ height:"100%",width:"100%", background: '#F1F1F1' }}>
 
                <div className="row justify-content-center" >
                    <div className="col-md-4 col-lg-4 p-4 box mt-5 mb-5 shadow-lg"
                        style={{
                            borderRadius: '8px', background: '#FFFFFF'
                        }}>
                        <div className="text-center mb-2">
                                    <img src={StampedoLogo} alt="Stampedo Logo" style={{ width: '100px', height: 'auto' }} />
                         </div>
                        <div className="text-center mb-3" style={{ fontSize: 24, color: '#222222', fontFamily: 'Poppins', fontWeight: 600, lineHeight: '28.80px' }}>Hi, Welcome Back</div>
                        <div className="text-center mb-4" style={{ fontSize: 15, color: '#7D7D7D', fontFamily: 'Poppins', fontWeight: 400, lineHeight: '26.56px' }}>Enter your credentials to continue</div>
                            <form  onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                       
                               <Field name="Email" type="email" component={renderField} label="Email Address / Username" />
                               <Field name="Password" type="password" component={renderPasswordField} label="Password" />
                      
                               
                        <div className="d-flex justify-content-md-between  mb-4">
                                        <div className="  align-content-center  d-flex">
                                          
                                            <i
                                                    className={`me-1 bi bi-${this.state.keepMeLoggedIn ? "check-square-fill " : "square"}`}
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        color: "black",
                                                        cursor: "pointer"
                                                    }}
                                                onClick={this.toggleChecked.bind(this)}
                                                >
                                                </i>
                                           
                                            
                                            <div className="ml-2" style={{ color: '#364152', fontSize: 13, fontFamily: 'Poppins' }}>Keep me logged in</div>
                            </div>
                                        <div className="highlight" style={{ color: '#673AB7', fontSize: 14, fontFamily: 'Poppins' }}>Forgot Password?</div>

                        </div>
                      
                        <button className="form-control btn-signin mb-4" type="submit"
                          style={{ color: '#31303D', fontSize: 16, fontFamily: 'Space Grotesk', textTransform: 'uppercase', background: '#FFD800', borderRadius: 300, height: 42 }}
                          disabled={submitting}
                         >Sign In</button>
                           
                        </form>
                        <div className="mb-2" style={{ width: '100%', height: '1px', background: '#E3E8EF' }} />
                        <div className="text-center" style={{ color: '#121926', fontSize: 14, fontFamily: 'Poppins' }}>Don't have an account?</div>
                    </div>
                </div>

               



                    </div>
                </BlockUi>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        accessToken: state.accessToken.accessToken,
        message: state.accessToken.message,
        status: state.accessToken.status,
        statusClass: state.accessToken.statusClass
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        login: login,
        logout: logout
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'loginForm',
    validate
})(LoginForm));

LoginForm.contextTypes = {
    router: PropTypes.object
}