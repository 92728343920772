import React, { Component } from "react";
import MenuItem from "./menu-item"
import '../style.css'

export default class RightMenuItemList extends Component {
    render() {
        const path = this.props.path;
        return (
            <ul className={this.props.cssClass} >
                <MenuItem text="Login" cssClass={`nav-link ${path === "/login" ? "active" : ""}`} url="/login" />
                <MenuItem text="Sign Up" cssClass={`stampedo_button nav-link ${path === "/signup" ? "active" : ""}`} url="/signup" />

            </ul>
        )
    }
}