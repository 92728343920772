import { SIGN_UP, SIGN_UP_RESET, ACCES_TOKEN, LOG_OUT } from "../actions/index"

var INITIAL_STATE = {
    signUpDone: null,
    accessToken: null,
    role:null,
    message: '',
    status: 0,
    statusClass: ''
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${SIGN_UP_RESET}`:
           
            return {
                ...state,
                signUpDone: null,
                message:"",
                status: 0,
                statusClass: 'ok'
            }
        case `${SIGN_UP}_PENDING`:
            return { ...state };
        case `${SIGN_UP}_FULFILLED`:
            console.log("sign-up fulfilled: ");
            console.log(action.payload);
            return {
                ...state,
                signUpDone: true,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
        case `${ACCES_TOKEN}_REJECTED`:
            return {
                ...state,
                signUpDone: null,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;


        case `${ACCES_TOKEN}_PENDING`:
            return { ...state };
        case `${ACCES_TOKEN}_FULFILLED`:
            console.log("running fulfilled access token, the role is: ");
            console.log(action.payload.data);
            return {
                ...state,
                accessToken: action.payload.data.token,
                role: action.payload.data.role,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
        case `${ACCES_TOKEN}_REJECTED`:
            return {
                ...state,
                accessToken: null,
                role: null,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        case LOG_OUT:
            localStorage.removeItem("accessToken");
            localStorage.removeItem("setupTime");
            console.log("running reducer logout");
            return {
                ...state,
                accessToken: null,
                role: null,
                message: '',
                status: 0,
                statusClass:''
            }
        default:
            console.log("its ending up here... ");
            return state;
    }
}