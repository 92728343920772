import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchSuppliers, addCard } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import PropTypes from "prop-types";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import showAlertBox from "../../tools/tools"

const validate = values => {
    const errors = {}
    const requiredFields = ['cardName', 'maxStamps', 'currentStamps', 'supplierId', 'used']
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    return errors
}

const renderTextField = ({ input, label, type, meta: { touched, error }, ...custom }) => (

    <TextField hintText={label}
        floatingLabelText={label}
        className="text-field"
        type={type}
        errorText={touched && error}
        {...custom}
        {...input}
    />
)


const renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => (

    <SelectField
        floatingLabelText={label}
        className="select-field"
        errorText={touched && error}
        {...input}
        onChange={(event, index, value) => input.onChange(value)}
        children={children}
        {...custom} />

)

export class CardInsertForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
        };
    }
    toggleBlocking(status) {
        this.setState({ blocking: status });
    }
    componentWillMount() {
        this.props.fetchSuppliers();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.status === 201) {
            this.toggleBlocking(false);
            showAlertBox("Card was added successfully", "alert-success");
            this.context.router.history.push("/admin/cards");
        }
        else if (nextProps.status === 400) {
            this.toggleBlocking(false);
            showAlertBox(nextProps.message, "alert-danger");
        }
    }
    onSubmit(props) {
        this.toggleBlocking(true);
        this.props.addCard(props);
    }
    renderSupplierItem() {
        return this.props.suppliers.map((supplier) => {
            return (
                <MenuItem key={supplier._id} value={supplier._id} primaryText={supplier.supplierName} />
            );
        })
    }

    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props
        return (
            <div className="col-md-12 col-sm-12">
                <BlockUi tag="div" blocking={this.state.blocking}>
                    <div className="page-header">
                        <h4 className="text-primary"> Add a new card </h4>
                    </div>
                    <div className="container">
                        <MuiThemeProvider muiTheme={getMuiTheme()}>
                            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                <div className="form-group">
                                    <Field type="text" name="cardName" component={renderTextField} label="Card Name" />
                                </div>
                                <div>
                                    <Field type="number" name="maxStamps" component={renderTextField} label="Max Stamps" />
                                </div>
                                <div>
                                    <Field type="number" name="currentStamps" component={renderTextField} label="Current Stamps" />
                                </div>
                                <div>
                                    <Field type="text" name="used" component={renderTextField} label="Current Stamps" />
                                </div>
                                <div>
                                    <Field name="supplierId" component={renderSelectField} label="Supplier">
                                        {this.renderSupplierItem()}
                                    </Field>
                                </div>
                                <div>
                                    <Field name="quantityPerUnit" component={renderTextField} label="details" multiLine={true} rows={2} />
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>Save</button>
                                    <button type="button" className="btn btn-default" disabled={pristine || submitting} onClick={reset}>Clear Values </button>
                                    <Link to="/admin/cards" className="btn btn-default">Go Back</Link>
                                </div>
                            </form>
                        </MuiThemeProvider>
                    </div>
                </BlockUi>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        suppliers: state.suppliers.suppliers,
        message: state.cards.message,
        status: state.cards.status,
        statusClass: state.cards.statusClass
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchSuppliers: fetchSuppliers,
        addCard: addCard,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'cardInsertForm',
    validate
})(CardInsertForm));

CardInsertForm.contextTypes = {
    router: PropTypes.object
}