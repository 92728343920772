import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter, Route } from 'react-router-dom'

import PublicPage from './components/public';
import App from './components/app';
import AdminApp from './components/admin-app';
import LoginForm from './components/login_form';
import SignupForm from './components/signup_form';
import ProtectedRoute from './components/ProtectedRoute';
// import reducers from './reducers';
// import promise from "redux-promise";
import store from './store';
import registerServiceWorker from './registerServiceWorker';

// export const createStoreWithMiddleware = applyMiddleware(promise)(createStore);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
        <div>
        <Route path="/public" component={PublicPage} />
        <Route path="/web" render={(props) => {
                    return < ProtectedRoute roles={["user"]} childComponent={<App {...props} />}  {...props}/> }  }  />
        <Route path="/admin" render={(props) => {
                    return < ProtectedRoute roles={[ "admin"]} childComponent={<AdminApp {...props} />}  {...props} />
                }} />
       
                <Route path="/login" component={LoginForm} />
                <Route path="/signup" component={SignupForm} />
        
            </div>
           
        </BrowserRouter>
      
  </Provider>
  , document.getElementById('container'));
registerServiceWorker();