import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import PaymentCard from './PaymentCard';
import './bb.css';

function PaymentPlans() {
  return (
    <div className="text-center text-dark container mt-5">
      <div className='fw-bold mb-2'>Tagline</div>
      <div className='h1 fw-bold'>Pricing Plan</div>
      <div className='subcaption mt-4 mb-5'>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet</div>

      {/* Carousel for mobile and desktop */}
      <Carousel
        controls={false}
        indicators={true}
        interval={null}
        className="d-lg-none carousel-indicators-dark"
      >
        <Carousel.Item>
          <PaymentCard
            title="Lorem ipsum dolor sit amet lor"
            description="Lorem ipsum dolor sit amet lorem dolor sit amet lorem ips"
            btnName="Learn more"
          />
        </Carousel.Item>
        <Carousel.Item>
          <PaymentCard
            title="Lorem ipsum dolor sit amet lorem"
            description="Lorem ipsum dolor sit amet lorem dolor sit amet lorem ips"
            btnName="Learn more"
          />
        </Carousel.Item>
      </Carousel>

      {/* Grid layout for larger screens */}
      <div className="row justify-content-center mt-5 d-none d-lg-flex">
        <div className="col-lg-4 col-sm-12 col-md-6">
          <PaymentCard
            title="Lorem ipsum dolor sit amet lor"
            description="Lorem ipsum dolor sit amet lorem dolor sit amet lorem ips"
            btnName="Learn more"
          />
        </div>
        <div className="col-lg-4 col-sm-12 col-md-6">
          <PaymentCard
            title="Lorem ipsum dolor sit amet lorem"
            description="Lorem ipsum dolor sit amet lorem dolor sit amet lorem ips"
            btnName="Learn more"
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentPlans;
