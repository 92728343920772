import React, { Component } from "react"
import { Link } from "react-router-dom"

export default class SupplierItem extends Component {
    render() {
        const currentSupplier = this.props.currentSupplier;
        const page = 1;//this.props.page;
        const supplierId = this.props.supId
        var cssClass = supplierId === currentSupplier ? "list-group-item active" : "list-group-item";

        return (
            <Link id={"data-" + supplierId} to={"/web/cards/" + this.props.supId + "/" + page} className={cssClass}>
                {this.props.supplierName}
            </Link>

        );
    }
}