import React, { Component } from "react";
import { supplierDetail, updateSupplier } from "../../actions/index"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import TextField from "material-ui/TextField";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import PropTypes from "prop-types"

import showAlertBox from "../../tools/tools"

const validate = values => {
    const errors = {}
    const requiredFields = ['supplierName', 'description']
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    return errors
}

const renderTextField = ({ input, label, type, defaultValue, name, id, meta: { touched, error }, ...custom }) => (

    <TextField hintText={label}
        floatingLabelText={label}
        className="text-field"
        defaultValue={defaultValue}
        type={type}
        id={id}
        onChange={(event) => input.onChange(event.target.value)}
        onBlur={(event) => input.onBlur(event.target.value)}
        errorText={touched && error}
        {...custom}
    />
)

class SupplierUpdateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
            supplierName: '',
            description:"",
            done: false
        };
    }
    toggleBlocking(status) {
        this.setState({ blocking: status });
    }
    componentWillMount() {
        this.setState({
            done: false
        });

        var supplierId = this.props.supplierId;
        if (supplierId === '0' && supplierId === undefined) {
            this.context.router.push("/admin/suppliers");

        }
        this.props.supplierDetail(supplierId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status === 204) {
            this.toggleBlocking(false)
            showAlertBox("Supplier was updated successfully", "alert-success");
            this.context.router.history.push("/admin/suppliers");
        }
        else if (nextProps.status === 400) {
            this.toggleBlocking(false)
            showAlertBox(nextProps.message, "alert-danger");
        }
        if (nextProps.supplier !== this.props.supplier) {
            this.setState({
                supplierName: nextProps.supplier.supplierName,
                description: nextProps.supplier.description,
                done: true
            });
        }
    }

    onSubmit(props) {
        console.log("My Props to update: " + props)
        this.toggleBlocking(true)
        this.props.updateSupplier(props);
    }

    render() {
        if (!this.state.done) {
            return (
                <div>Loading...</div>
            )
        }
        const { handleSubmit } = this.props
        return (
            <div className="col-md-12 col-sm-12">
                <BlockUi tag="div" blocking={this.state.blocking}>
                    <div className="page-header">
                        <h4 className="text-primary"> Update Supplier </h4>
                    </div>
                    <div className="container">
                        <MuiThemeProvider muiTheme={getMuiTheme()}>
                            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                <div className="form-group">
                                    <Field type="text" name="supplierName" id="supplierName" defaultValue={this.state.supplierName} component={renderTextField} label="Supplier Name" />
                                </div>
                                <div>
                                    <Field name="description" defaultValue={this.state.description} component={renderTextField} label="Description" multiLine={true} rows={2} />
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                    <Link to="/admin/suppliers" className="btn btn-default">Go Back</Link>
                                </div>
                            </form>
                        </MuiThemeProvider>
                    </div>
                </BlockUi>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        supplier: state.suppliers.supplier,
        initialValues: state.suppliers.supplier,
        message: state.suppliers.message,
        status: state.suppliers.status,
        statusClass: state.suppliers.statusClass
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        supplierDetail: supplierDetail,
        updateSupplier: updateSupplier
    }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'SupplierUpdateForm',
    enableReinitialize: true,
    validate
})(SupplierUpdateForm));

SupplierUpdateForm.contextTypes = {
    router: PropTypes.object
}