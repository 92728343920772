import React, { Component } from "react";
import io from "socket.io-client";
import { connect } from "react-redux";
import { cardDetail } from "../../actions/index";
import QRCode from "react-qr-code";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.css";
import bootbox from "bootbox";

window.jQuery = $;
require("bootstrap");

class CardDetail extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    const cardId = this.props.match.params.cardId;
    this.props.cardDetail(cardId);
  }

  componentDidMount() {
    const socket = io("http://localhost:5000");

    const cardId = this.props.match.params.cardId;

    socket.emit("roomName", { roomName: cardId });

    socket.on("pushNotify", data => {
      console.log(data.card.used);
      if (parseInt(data.card.used) == true) {
        bootbox.alert({
          message: "Card is used!",
          size: "small"
        });
      }
      // alert(data.card.cardName)
    });
  }

  setUsed() {
    var maxStamps = document.getElementById("maxStamps").value;
    var currentStamps = document.getElementById("currentStamps").value;
    if (!isNaN(maxStamps) && !isNaN(currentStamps)) {
      if (parseInt(maxStamps) === parseInt(currentStamps)) {
        document.getElementById("used").value = "true";
      }
    } else {
      document.getElementById("used").value = "false";
    }
  }
  rendercard(card) {
    var imgStyle = {
      width: "100%"
    };
    var supplierStyle = {
      marginTop: "0%"
    };
    var borderStyle = {
      border: "0px solid gray"
    };
    var paddingBottom = {
      paddingBottom: "20px"
    };
    var marginRight = {
      marginRight: "20px"
    };
    var cursorPointer = {
      cursor: "pointer"
    };
    var displayStyle = {
      display: "inline-block"
    };

    let displayClass = "";
    let displayClass2 = "";

    return (
      <div>
        <div className="col-xs-4 item-photo">
          <img style={imgStyle} src="/images/card.jpg" />
        </div>
            <div className="col-xs-5" style={borderStyle}>
                {/* <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "80%", width: "80%" }}
                    value={card.supplierId}
                    viewBox={`0 0 128 128`}
                />
                */}
                <span id="supplierId">{card.googleUid}</span>
          <h3></h3>
          <h1 className="title-price">
            <small>Card ID</small>
          </h1>
          <h3 style={supplierStyle}>
            <span id="supplierId">{card._id}</span>
          </h3>
          <div className={displayClass} style={paddingBottom} id="maxStampsBox">
            <h6 className="stamps-attr">
                        <small>Max Stamps</small>
                        <span className="pull-right">
                            {card.maxStamps}
                        </span>
            </h6>
          </div>
          <div className={displayClass} style={paddingBottom} id="currentStampsBox">
            <h6 className="stamps-attr">
                        <small>Current Stamps</small>
                        <span className="pull-right">
                            {card.currentStamps}
                        </span>
            </h6>
          </div>
          <div
            className={displayClass2}
            style={paddingBottom}
            id="usedBox"
          >
            <span style={marginRight} aria-hidden="true" />
            The card is used
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.card) {
      return <h1>Loading</h1>;
    } else {
      return this.rendercard(this.props.card);
    }
  }
}

const mapStateToProps = state => {
  return {
    card: state.cards.card
  };
};

const mapDispatchToProps = {
  cardDetail: cardDetail
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardDetail);
