import React, { Component } from 'react';
import CardList from "./card_list"

import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
const data = [{ name: 'Nr Cards', uv: 400 }, { name: 'No Stamps', uv: 200 }
    , { name: '1 Stamp', uv: 20 }
    , { name: '2 Stamps', uv: 20 }
    , { name: '3 Stamps', uv: 60 }
    , { name: '4 Stamps', uv: 80 }
    , { name: '5 Stamps', uv: 20 }
    , { name: '6 Stamps', uv: 30 }];
// import PropTypes from "prop-types"

export default class Cards extends Component {
    renderSOmething() {
        const supplierId = this.props.match.params.supplierId === undefined ? 0 : this.props.match.params.supplierId;
        const page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page
        return (
            <CardList supplierId={supplierId} page={page} />
        );
    }
    render() {
        return (
            <div className="row mt-2">
             <div className="col-8">   

                <div className="row" >
                <div className="col-6">
                <BarChart width={400} height={200} data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
               
                    <Bar dataKey="uv" fill="#8884d8" />
                    </BarChart>
                    </div>
                     <div className="col-6">
             <BarChart  width={400} height={200} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
               
                <Bar dataKey="uv" fill="#8884d8" />
                </BarChart>
                </div>
                </div>
                 <div className="row" >
                <div className="col-6">
                <BarChart width={400} height={200} data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
               
                    <Bar dataKey="uv" fill="#8884d8" />
                    </BarChart>
                    </div>
                     <div className="col-6">
             <BarChart  width={400} height={200} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
               
                <Bar dataKey="uv" fill="#8884d8" />
                </BarChart>
                </div>
                </div>
                </div>
                <div className="col-4">
                </div>
                </div>
        );
    }

}