import "@fontsource/space-grotesk"; // Defaults to weight 400
import './bb.css';
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import digitalLoyalty from '../../assets/digital_loyalty.svg'
import medal from '../../assets/medal.svg'
import BusinessBenefits from './BusinessBenefits'
import PaymentPlans from './PaymentPlans'
import Hero from './Hero'
import Faqs from './Faqs'
import Footer from './Footer'
import CustomerTestemonials from './CustomerTestemonials'
import CustomerBenefits from './CustomerBenefits'
import CustomerLogos from './CustomerLogos'


export default class HomePage extends Component {
   
   
   

    render() {
        return (
            <div>
                <Hero/>
                <CustomerBenefits/>
                <BusinessBenefits/>
                <PaymentPlans/>
                <Faqs/>
                <CustomerTestemonials/>
                <CustomerLogos/>
                <Footer/>
              
               
            </div>
           
        );
    }
}

