import React, { Component } from 'react';
import { SettingsPane, SettingsPage, SettingsContent, SettingsMenu } from 'react-settings-pane'
import "./style.css";
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { settingsLoaded, settingsSaved, settingsLogoSelected, settingsMsHeaderSelected, cropHasBeenSet, croppedImageSet, cropImageUploaded } from "../../actions/index"

import 'react-image-crop/dist/ReactCrop.css';
// import PropTypes from "prop-types"
import CropImageUpload from "./logo_input";


class Settings extends Component {


    componentWillMount() {

        this.props.settingsLoaded();
    }


    // Render function of any of your components:

    menu = [
        {
            title: 'General',          // Title that is displayed as text in the menu
            url: '/settings/general'  // Identifier (url-slug)
        },
        {
            title: 'Digital Card Template',
            url: '/settings/cardtemplate'
        },
        {
            title: 'Paper Card Template',
            url: '/settings/papercardtemplate'
        },
        {
            title: 'Master Sticker Template',
            url: '/settings/masterstickertemplate'
        }

    ];

    settingsChanged = (changedSettings) => {
        // this is triggered onChange of the inputs

    };
    renderGeneral = () => {
        return (

            <SettingsPage handler="/settings/general">
                <fieldset className="form-group">
                    <label htmlFor="profileName">Name: </label>
                    <input type="text" className="form-control" name="mysettings.general.name" placeholder="Name" id="general.ame" onChange={this.settingsChanged} defaultValue={this.settings.general.name} />
                </fieldset>
                <fieldset className="form-group">
                    <label htmlFor="profileColor">Color-Theme: </label>
                    <select name="mysettings.general.color-theme" id="profileColor" className="form-control" defaultValue={null}>
                        <option value="blue">Blue</option>
                        <option value="red">Red</option>
                        <option value="purple">Purple</option>
                        <option value="orange">Orange</option>
                    </select>
                </fieldset>
            </SettingsPage>


        )


    }


    renderDigitalCardTemplate = () => {
        const backgroundColorChanged = (event) => {

            this.settings.cardtemplate.backgroundcolor = event.target.value;
        };
        //This code defines a function called `logoChanged` that takes an `event` parameter.Inside the function, a new `FileReader` object is created. The `FileReader` object is used to read the contents of the selected file.
        //An event listener is added to the`load` event of the`FileReader`.When the file has finished loading, the event listener callback function is executed.Inside the callback function, the`reader.result` property 
        //is passed as an argument to the`settingsLogoSelected` function, which is a prop passed to the component.This function is responsible for handling the selected logo and updating the component's state or performing any other necessary actions.
        //Finally, the `readAsDataURL` method of the `FileReader` is called with the first file from the `event.target.files` array as the argument. This method reads the file as a data URL, which can be used to display the file contents, such as an image.
        const logoChanged = (event) => {
            // this.settings.cardtemplate.logo = event.target.value;
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.props.settingsLogoSelected(reader.result);
            }
            );

            reader.readAsDataURL(event.target.files[0]);

        };
        const titleChanged = (event) => {

            this.settings.cardtemplate.dig_pass_title = event.target.value;
        };
        const maxNrStampsChanged = (event) => {
            this.settings.cardtemplate.max_nr_stamps = event.target.value;
        };
        return (
            <SettingsPage handler="/settings/cardtemplate" >
                <fieldset className="form-group">
                    <label htmlFor="cardtitle">Digital Pass Title: </label>
                    <input type="text" className="form-control" name="mysettings.cardtemplate.dig_pass_title" placeholder="Digital Pass Title" id="cardtemplate.title" onChange={titleChanged} defaultValue={this.settings.cardtemplate.dig_pass_title} />

                </fieldset>
                <fieldset className="form-group">
                    <label htmlFor="passlogo">Pass Logo: </label>
                    <div>
                        <label htmlFor="cardtemplate.logo" className="custom-file-upload btn btn-sm btn-primary pull-left">
                            Choose File
                        </label>
                        <input type="file" className="custom-file-input" name="mysettings.cardtemplate.logo" placeholder="Logo" id="cardtemplate.logo" onChange={logoChanged} />
                        {this.props.croppedImageUrl && (
                            <img alt="Crop" className="Cropped_image_style_mm" src={this.props.croppedImageUrl} />
                        )}
                    </div>
                </fieldset>
                <fieldset className="form-group">
                    <label htmlFor="maxnrstamps">Max Number of Stamps: </label>
                    <input type="text" className="form-control" name="mysettings.cardtemplate.max_nr_stamps" placeholder="Nr Stamps" id="cardtemplate.max_nr_stamps" onChange={maxNrStampsChanged} defaultValue={this.settings.cardtemplate.max_nr_stamps} />

                </fieldset>
                <fieldset className="form-group">
                    <label htmlFor="backgroundColor">Background Color: </label>
                    <br />

                    <input type="color" id="template_color" className="form-control" defaultValue={this.settings.cardtemplate.backgroundcolor} onChange={backgroundColorChanged}

                    />

                </fieldset>

            </SettingsPage>

        );


    }

    renderMasterStickerTemplate = () => {
        const msTitleChanged = (event) => {

            this.settings.mstemplate.ms_title = event.target.value;
        }
        const msHeaderChanged = (event) => {

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.props.settingsMsHeaderSelected(reader.result);
            }
            );

            reader.readAsDataURL(event.target.files[0]);

        }
        return (
            <SettingsPage handler="/settings/masterstickertemplate" >
                <fieldset className="form-group">
                    <label htmlFor="stickertitle">Master Sticker Title: </label>
                    <input type="text" className="form-control" name="mysettings.masterstickertemplate.ms_st_title" placeholder="Master Sticker Title" id="masterstickertemplate.title" onChange={msTitleChanged} defaultValue={this.settings.mstemplate.ms_title} />
                    <label htmlFor="mstemplate.header" className="custom-file-upload btn btn-sm btn-primary pull-left">
                        Choose File
                    </label>
                    <input type="file" className="custom-file-input" name="mysettings.mstemplate.header" placeholder="" id="mstemplate.header" onChange={msHeaderChanged} />
                    {this.props.croppedMsHeaderUrl && (
                        <img alt="Crop" className="Cropped_msheader_style_mm" src={this.props.croppedMsHeaderUrl} />
                    )}

                </fieldset>


            </SettingsPage>
        )
    }
    render = () => {


        if (this.props.general.name == "") {
            return (
                <div>Loading...</div>
            )
        }

        // Save settings after close
        const leavePaneHandler = (wasSaved, newSettings, oldSettings) => {
            // "wasSaved" indicates wheather the pane was just closed or the save button was clicked.

            if (wasSaved) {
                // do something with the settings, e.g. save via ajax.
                console.log("the settings were saved");
                this.settings.cardtemplate.logo = this.props.croppedImageUrl;
                this.settings.mstemplate.ms_header = this.props.croppedMsHeaderUrl;
                this.props.settingsSaved(this.settings);

            }
        };

        this.settings = {
            general: this.props.general,
            cardtemplate: this.props.cardtemplate,
            mstemplate: this.props.mstemplate


        };

        return (
            <div>
                <SettingsPane items={this.menu} index="/settings/general" settings={this.settings} onPaneLeave={leavePaneHandler}>
                    <SettingsMenu headline="Settings" />
                    <SettingsContent closeButtonClass="" saveButtonClass="" header={true}>
                        {this.renderGeneral()}
                        {this.renderDigitalCardTemplate()}
                        {this.renderMasterStickerTemplate()}


                    </SettingsContent>
                </SettingsPane>
                <CropImageUpload circularCrop={this.props.circularCrop} />
                {/*this.renderCropImageDialog()*/}


            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        general: state.settings.general,
        cardtemplate: state.settings.cardtemplate,
        mstemplate: state.settings.mstemplate,
        croppedImageUrl: state.settings.croppedImageUrl,
        croppedMsHeaderUrl: state.settings.croppedMsHeaderUrl,
        circularCrop: state.settings.circularCrop
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({

        settingsLoaded: settingsLoaded,
        settingsSaved: settingsSaved,
        settingsLogoSelected: settingsLogoSelected,
        settingsMsHeaderSelected: settingsMsHeaderSelected,
    }, dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
