import './bb.css';
import LongCard from './LongCard';
import NormalCard from './NormalCard';
import MedalIcon from '../../assets/medalIcon.svg';
import VectorIcon from '../../assets/Vector1.svg'
import VectorIcon2 from '../../assets/Vector2.svg'

function BusinessBenefits() {
  return (
    <div className="text-center  text-dark mt-md-5 grey_background">
      <div className='fw-bold mb-2'>Tagline</div>
      <div className='h1 fw-bold'>The Ultimate Loyalty Solution</div>
      <div className='subcaption mt-4 mb-5'>Digitalize your loyalty cards and unlock exclusive benefits</div>
      <div className="row  gx-0mt-5 ms-1 me-1">
        <div className="col-lg-6 col-sm-12 col-md-12">
          <LongCard
            title="Secure Benefits"
            description="Collect and enjoy rewards hassle-free with our secure digital platform"
            btnName="Sign Up"
            icon={MedalIcon} />
            {/* <img src="assets/shadow.png" /> */}
            <div className="card-shadow">
            </div>
        </div>
        <div className="col-lg-3 col-sm-12 col-md-6">
          <NormalCard
            title="Efficient Customer Statistics"
            description="Track and analyze customer data for better insights and growth"
            btnName="Learn more"
            icon={VectorIcon} />
        </div>
        <div className="col-lg-3 col-sm-12 col-md-6" >
          <NormalCard
            title="Customer Outreach Capabilities"
            description="Engage with your customers through targeted marketing campaigns"
            btnName="Learn more"
            icon={VectorIcon2} />
        </div>
      </div>
    </div>
  );
}

export default BusinessBenefits;
