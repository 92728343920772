import React, { Component } from 'react';
import QRCode from "react-qr-code";
import { HOSTNAME } from "../../actions/index"
// import PropTypes from "prop-types"

export default class Print extends Component {
    render() {
        const supplierId = this.props.match.params.supplierId === undefined ? 0 : this.props.match.params.supplierId;
        const page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page
        const pathBase = window.location.origin;
        //const pathBase = HOSTNAME;

        return (
            <div>
                <div className="alert alert-success">
            Please scan the master code
                </div>
                

                <div>
                    
                <img alt="Crop" className="Cropped_msheader_style_mm" src={this.props.croppedMsHeaderUrl} />
                    
              <QRCode 
                    size={128}
                        style={{ height: "auto", maxWidth: "30%", width: "30%" }}
                        value={ pathBase + "/public/gengooglepass"}
                        viewBox={`0 0 128 128`}
                    />
                </div>
            </div>
           
        );
    }
}