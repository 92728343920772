import React, { Component } from "react";
import SupplierInsertForm from "./supplier-insert";
import SupplierUpdateForm from "./supplier-update";


export default class SupplierForm extends Component {

    render() {
        const supplierId = this.props.match.params.supplierId;
        console.log("supplierId: " + supplierId)

        if (supplierId !== undefined) {
            return (
                <SupplierUpdateForm supplierId={supplierId} />
            );
        } else {
            return (
                <SupplierInsertForm />
            );
        }


    }

}
