import './bb.css';
import FaqCard from './FaqCard';

function Faqs() {
  return (
    <div className="text-center text-dark container mt-5 white_box mb-5">
      <div className='fw-bold mb-2'>Tagline</div>
      <div className='h1 fw-bold'>FAQs</div>
      <div className='subcaption mt-4 mb-5'>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet</div>
      <div className="row justify-content-center mt-5">
           <div className=" col-12 col-md-8">
            <FaqCard
            title="How did you get the idea?"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.  Duis cursus, mi quis viverra ornare, eros dolor interdum  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique"

           />
           <FaqCard
            title="Can I still use my old cards?"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.  Duis cursus, mi quis viverra ornare, eros dolor interdum  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique"

           />
           <FaqCard
            title="Would it work to buy some cards?"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.  Duis cursus, mi quis viverra ornare, eros dolor interdum  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique"

           />
           <FaqCard
            title="Question text goes here?"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.  Duis cursus, mi quis viverra ornare, eros dolor interdum  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique"

           />
           <FaqCard
            title="Question text goes here?"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.  Duis cursus, mi quis viverra ornare, eros dolor interdum  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique"

           />
           </div>
             <div className='h1 fw-bold mt-4'>Still have Questions?</div>
            <div className='subcaption mt-4 mb-5'>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet</div>
            <div className="col-12 col-md-3">
                <button className="container-fluid btn btn-yellow align-self-center rounded-5 text-uppercase px-3 py-2">
                Contact Us
                </button>
            </div>
      </div>
     
    </div>
  );
}

export default Faqs
