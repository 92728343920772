import React, { Component } from "react";
import MenuItem from "./menu-item"

export default class MainMenuItemList extends Component {
    render() {
        const path = this.props.path;
        return (
            <ul className={this.props.cssClass}>
                <MenuItem text="Home" cssClass={`nav-link ${path === "/public" ? "active" : ""}`} url="/public" />
                <MenuItem text="Our Customers" cssClass={`nav-link ${path === "/public/customers" ? "active" : ""}`} url="/public/customers" />
                <MenuItem text="About" cssClass={`nav-link ${path === "/public/about" ? "active" : ""}`} url="/public/about" />
            </ul>
        )
    }
}