import axios from "axios";

//export const HOSTNAME = "http://192.168.0.12"
//export const HOSTNAME = "http://localhost"
//export const HOSTNAME = "http://23.88.56.36"
export const HOSTNAME = "http://" + window.location.hostname;
var API_URL_temp;
console.log("hostname: " + HOSTNAME);

if (HOSTNAME.includes("stampedo-card.de")) {
  API_URL_temp = "https://api.stampedo-card.de";
}
else {
    API_URL_temp = HOSTNAME + ":3300";
}

const API_URL = API_URL_temp
const CARD_URL = "/api/card";
const ACCES_TOKEN_URL = "/auth/token";
const SIGNUP_URL = "/auth/signup";
const CARD_CRUD_URL = "/api/admin/cards";
const SUPPLIER_CRUD_URL = "/api/suppliers";
const GOOGLE_PASS_CRUD_URL = "/pub/gengooglecard";
const SETTINGS_URL = "/api/settings"
const CROP_IMAGE_UPLOAD_URL = "/api/upload"

export const FETCH_CARD_LIST = "FETCH_CARD_LIST";
export const FETCH_ADMIN_CARD_LIST = "FETCH_ADMIN_CARD_LIST";
export const CARD_DETAIL = "CARD_DETAIL";
export const GET_GOOGLE_PASS = "GET_GOOGLE_PASS";
export const FETCH_SUPPLIER_LIST = "FETCH_SUPPLIER_LIST";
export const SUPPLIER_DETAIL = "SUPPLIER_DETAIL";
export const ACCES_TOKEN = "ACCES_TOKEN";
export const LOG_OUT = "LOG_OUT";
export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_RESET = "SIGN_UP_RESET";

export const CARD_CREATE = "CARD_CREATE";
export const CARD_UPDATE = "CARD_UPDATE";
export const CARD_DELETE = "CARD_DELETE";

export const SUPPLIER_CREATE = "SUPPLIER_CREATE";
export const SUPPLIER_UPDATE = "SUPPLIER_UPDATE";
export const SUPPLIER_DELETE = "SUPPLIER_DELETE";

export const SCAN_SCANNED_CARD = "SCAN_SCANNED_CARD";
export const SCAN_PASS_DISPLAYED = "SCAN_PASS_DISPLAYED";
export const SCAN_STAMP_ADDED = "SCAN_STAMP_ADDED";
export const SCAN_CARD_USED = "SCAN_CARD_USED";

export const SETTINGS_LOADED = "SETTINGS_LOADED";
export const SETTINGS_SAVED = "SETTINGS_SAVED";
export const SETTINGS_LOGO_SELECTED = "SETTINGS_LOGO_SELECTED";
export const SETTINGS_MS_HEADER_SELECTED = "SETTINGS_MS_HEADER_SELECTED";
export const SETTINGS_CROP_SET = "SETTINGS_CROP_SET";
export const SETTINGS_CROP_IMAGE_SET = "SETTINGS_CROP_IMAGE_SET";
export const SETTINGS_CROP_MS_HEADER_SET = "SETTINGS_CROP_MS_HEADER_SET";
export const SETTINGS_CROP_IMAGE_UPLOADED = "SETTINGS_CROP_IMAGE_UPLOADED";

///Cupplier Action Start



export function cropImageUploaded(blob) {
    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
     
    
    let fileName = blob.name;
    console.log("Filename: " + fileName);
    let file = new File([blob], fileName, { type: "image/jpeg" });
    let formData = new FormData();
    formData.append('file', file, blob.name);
    formData.append('name', 'name of the file');

   
    /*
    axios.post("https://httpbin.org/post", formData, {
        headers: {
            'content-ype': `multipart/form-data`
        }
    })
        .then(res => { console.log("success on httpbin"); console.log(res) } )
        .catch(err => { console.log("error: "); console.log(err) });
        */
    var request = axios.post(API_URL + CROP_IMAGE_UPLOAD_URL, formData, {
        headers: {
            'Content-Type': `multipart/form-data`,
            
            "access-token": token.accessToken
        }

    });

    return {
        type: SETTINGS_CROP_IMAGE_UPLOADED,
        payload: request
    };
}


export function cropHasBeenSet(crop) {
   
    return {
        type: SETTINGS_CROP_SET
        ,
        payload: crop
    };
}

export function croppedImageSet(cropImageUrl) {
 
    return {
        type: SETTINGS_CROP_IMAGE_SET
        ,
        payload: cropImageUrl
    };
}


export function settingsLoaded() {
    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    const request = axios.get(API_URL + SETTINGS_URL, {
        headers: {
            "access-token": token.accessToken
        }});
    return {
        type: SETTINGS_LOADED
        ,
        payload: request
    };
}

export function settingsLogoSelected(logo_img) {
    return {
        type: SETTINGS_LOGO_SELECTED,
        payload: logo_img
    };
}

export function settingsMsHeaderSelected(logo_img) {
    return {
        type: SETTINGS_MS_HEADER_SELECTED,
        payload: logo_img
    };
}


export function settingsSaved(props) {
   

    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    let updatedCardTemplate =
    {
        ...props.cardtemplate,
        ...props.mstemplate
        
    }
    console.log("updatedCardTemplate:");
    console.log(updatedCardTemplate);
    var request = axios.put(API_URL + SETTINGS_URL, updatedCardTemplate, {
        headers: {
            "access-token": token.accessToken
        }
    });
    return {
        type: SETTINGS_SAVED,
        payload: request
    };
}


export function cardUsed(props) {
    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    let updateCard =
    {
        ...props,
        used: true 
    }

    var request = axios.put(API_URL + CARD_CRUD_URL, updateCard, {
        headers: {
            "access-token": token.accessToken
        }
    });

    return {
        type: SCAN_CARD_USED,
        payload: request
    };
}


export function stampAdded(props) {
    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    let updateCard =
    {
        ...props,
        currentStamps: props.currentStamps+1
    }
  
    var request = axios.put(API_URL + CARD_CRUD_URL, updateCard, {
        headers: {
            "access-token": token.accessToken
        }
    });

    return {
        type: SCAN_STAMP_ADDED,
        payload: request
    };
}



export function displayScannedCard(cardId) {
    console.log("this function is called! :)");
    const request = axios.get(API_URL + CARD_URL + "/" + cardId);
    return {
        type: SCAN_PASS_DISPLAYED,
        payload: request
    };
}


export function cardIdScanned(cardID) {
    console.log("Action Data is: " + cardID);
    return {
        type: SCAN_SCANNED_CARD,
        payload: cardID
    };
}



export function fetchSuppliers() {
  const request = axios.get(API_URL + SUPPLIER_CRUD_URL);
  return {
    type: FETCH_SUPPLIER_LIST,
    payload: request
  };
}

export function supplierDetail(supplierId) {
  const request = axios.get(API_URL + SUPPLIER_CRUD_URL + "/" + supplierId);
  return {
    type: SUPPLIER_DETAIL,
    payload: request
  };
}

export function addSupplier(props) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
    }
    var request = axios.post(API_URL + SUPPLIER_CRUD_URL, props, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: SUPPLIER_CREATE,
    payload: request
  };
}

export function updateSupplier(props) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
    }
    console.log("UPDATE FOLLOWING DATA: ")
    console.log(props);
  var request = axios.put(API_URL + SUPPLIER_CRUD_URL, props, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: SUPPLIER_UPDATE,
    payload: request
  };
} 

export function deleteSupplier(id) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
  }
  var request = axios.delete(API_URL + SUPPLIER_CRUD_URL + "/" + id, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: SUPPLIER_DELETE,
    payload: request
  };
}
///Supplier Action end

///Card Action Start
export function fetchCards(supplierId, page) {
  const request = axios.get(
    API_URL + CARD_URL + "/" + supplierId + "/" + page
  );
  return {
    type: FETCH_CARD_LIST,
    payload: request
  };
}

export function fetchAdminCards(page) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
  }
  var request = axios
    .get(API_URL + CARD_CRUD_URL + "/" + page, {
      headers: {
        "access-token": token.accessToken
      }
    })
    .catch(error => {
      console.log("Sorry, something is wrong: " + error);
      if (error.response) {
        // console.log(error.response.data.error_description);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        // tokenData.accessToken = '';
        // tokenData.message = response.data.error_description
      } else {
        console.log("Error", error.message);
        accessToken.accessToken = "";
        accessToken.message = error.message;
      }
      console.log(error.config);
    });
  return {
    type: FETCH_ADMIN_CARD_LIST,
    payload: request
  };
}


export function fetchGooglePass() {
    //console.log(props);
    const request = axios.get(API_URL + GOOGLE_PASS_CRUD_URL );
    return {
        type: GET_GOOGLE_PASS,
        payload: request
    };


}
export function addCard(props) {
  console.log(props);
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
  }
  var request = axios.post(API_URL + CARD_CRUD_URL, props, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: CARD_CREATE,
    payload: request
  };
}

export function updateCard(props) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
  }
  var request = axios.put(API_URL + CARD_CRUD_URL, props, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: CARD_UPDATE,
    payload: request
  };
}

export function deleteCard(id) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
  }
  var request = axios.delete(API_URL + CARD_CRUD_URL + "/" + id, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: CARD_DELETE,
    payload: request
  };
}

export function cardDetail(cardId) {
    console.log("theId is: " + cardId);
  const request = axios.get(API_URL + CARD_URL + "/" + cardId);
  return {
    type: CARD_DETAIL,
    payload: request
  };
}

export function signUpReset() {

    return {
        type: SIGN_UP_RESET,
        payload: null
    };
}

export function signup(props) {

    const data = {
        fullName: props.Name,
        lastName: props.Surname,
        email: props.Email,
        password: props.Password
    };
    console.log("post api: " + API_URL + SIGNUP_URL );


    //console.log(data);
   
    var request = axios.post(API_URL + SIGNUP_URL, data);
    return {
        type: SIGN_UP,
        payload: request
    };
}


export function login(props) {
  const data = {
    email: props.Email,
    password: props.Password
  };

  var request = axios.post(API_URL + ACCES_TOKEN_URL, data);
  return {
    type: ACCES_TOKEN,
    payload: request
  };
}

export function logout(props) {

    return {
        type: LOG_OUT,
        payload: null
    };

}