import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import StampedoLogo from '../../assets/gpt_logo.png';
import clipartThankyou from "../../assets/undraw_choose_re_7d5a 1.svg";


export default class ThankYouPage extends Component {


    render() {
        console.log("login render");
        const { handleSubmit, submitting } = this.props
        return (
            <div style={{
                height: "100%", width: "100%", background: '#F1F1F1',
                position: "absolute",
                top: 0,
                left: 0
            }}>
               
                <div className="text-center mt-4 mb-2">
                    <img src={StampedoLogo} alt="Stampedo Logo" style={{ width: '100px', height: 'auto' }} />
                </div>
                        <div className="container" style={{ height: "100%", width: "100%", background: '#F1F1F1' }}>
                   
                        <div className="row justify-content-center" >
                            <div className="col-md-7 col-lg-7 p-4 box mt-5 mb-5 shadow-lg d-flex"
                                style={{
                                    borderRadius: '8px', background: '#FFFFFF'
                                }}>
                            <img src={clipartThankyou} className="me-2" alt="Stampedo Logo" style={{  height: 'auto' }} />
                                <div className="">
                                <div className="text-left mb-3" style={{ fontSize: 24, color: '#222222', fontFamily: 'Poppins', fontWeight: 600, lineHeight: '28.80px' }}>You're Almost There! Verification Needed</div>
                                <div className="text-left mb-3" style={{ color: '#0FBA3F', fontSize: 18, fontFamily: 'Poppins', fontWeight: '700', lineHeight: '27px' }}>Your payment has been successfully processed. </div>
                                <div className="text-left" style={{ fontSize: 15, color: '#7D7D7D', fontFamily: 'Poppins', fontWeight: 400, lineHeight: '26.56px' }}>To complete your  registration, please verify your email address.
                                </div>
                                <div className="text-left mb-4" style={{ fontSize: 15, color: '#7D7D7D', fontFamily: 'Poppins', fontWeight: 400, lineHeight: '26.56px' }}>

                                    Check your inbox for a  verification email and follow the instructions.
                                </div>
                                <div className="text-left mb-4" style={{ fontSize: 15, color: '#7D7D7D', fontFamily: 'Poppins', fontWeight: 400, lineHeight: '26.56px' }}>

                                    Once verified, you can  log in to our website. Thank you!
                                </div>

                                
                                    
                                <Route path="/public" className="btn btn-outline-none" style={{ textDecoration: 'none' }}>
                                    <button className="column-4  mb-4" type="submit"
                                        style={{ color: '#31303D', fontSize: 16, fontFamily: 'Space Grotesk', textTransform: 'uppercase', background: '#FFD800', border: 'none', borderRadius: 300, height: 42 }}
                                        disabled={submitting}
                                    >return to home page</button>
                                </Route>
                            </div>

                                
                               
                        </div>
                        </div>





                    </div>
            </div>

        );
    }
}
   
