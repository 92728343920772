import './bb.css';
import LogoCard from './LogoCard';
import StampedoLogo from '../../assets/gpt_logo.png';
import Facebook from '../../assets/facebook.svg';
import Instagram from '../../assets/insta.svg';
import X from '../../assets/x.svg';
import Linkedin from '../../assets/linkedin.svg';
import Youtube from '../../assets/youtube.svg';

function Footer() {
  return (
    <div className="mt-5  align-items-start justify-content-between ">
    <div className = "row gx-0 " >
      <div className="col-12 col-md-2 justify-content-left " >
        <div className="row gx-0 justify-content-center justify-content-md-left align-self-center  align-items-center align-items-md-start" >
            <img className="col-2 col-md-3 align-self-center img-fluid" src={StampedoLogo} alt="logo"/>
            <p className="col-6 col-md-6  h3 align-self-center"> Stampedo</p>
        </div>
      </div>
      <div className="col-12 col-md-8 row gx-0 align-items-center justify-content-center mt-1 mt-md-0">
         <div className="col-12 col-md-2 ms-md-2 text-center ">LINK ONE</div>
         <div className="col-12 col-md-2 text-center">LINK TWO</div>
         <div className="col-12 col-md-2 text-center">LINK THREE</div>
         <div className="col-12 col-md-2 text-center me-md-2">LINK FOUR</div>
       </div>

      <div className="col-12 col-md-2 d-flex mt-3 mt-md-0 align-self-center  justify-content-center" >
      <img className="img-fluid align-self-center me-2" src={Facebook} alt="social" />
      <img className="img-fluid align-self-center ms-2 me-2" src={Instagram} alt="social" />
      <img className="img-fluid align-self-center ms-2 me-2" src={X} alt="social" />
      <img className="img-fluid align-self-center ms-2 me-2" src={Linkedin} alt="social" />
      <img className="img-fluid align-self-center ms-2" src={Youtube} alt="social" />
      </div>
      </div>
     <div className="row gx-0 justify-content-center mt-5 border-top p-4">
              
            <i className="bi bi-c-circle text-center"> 2024 Stampedo. All rights reserved.</i>
     </div>
      </div>
  );
}

export default Footer
