import React, { Component } from "react";
import MenuItem from "./menu-item"

export default class AdminMenuItemList extends Component {
    render() {
        const path = this.props.path;
        return (
            <ul className={this.props.cssClass}>
                <MenuItem text="Home" cssClass={path === "/admin" ? "active" : ""} url="/admin" />
                <MenuItem text="Cards" cssClass={path === "/admin/cards" ? "active" : ""} url="/admin/cards" />
                <MenuItem text="Suppliers" cssClass={path === "/admin/suppliers" ? "active" : ""} url="/admin/suppliers" />
            </ul>
        )
    }
}