import './bb.css';
import TestemonialCard from './TestemonialCard';
import Bubble from '../../assets/bubble.svg'

function CustomerTestemonials() {
  return (
    <div className="text-center text-dark container mt-5 mb-5">
      <div className='h1 fw-bold'>Customer Testemonials</div>
      <div className='subcaption mt-4 mb-5'>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet</div>
      <div className="row bubble_background align-items-top">
      
      <div id="carouselCustomerTestemonials" className="carousel slide carousel-dark overflow-hidden p-4" data-bs-ride="carousel">
           
            <div className="carousel-indicators carousel-indicators-round">
                <button type="button" data-bs-target="#carouselCustomerTestemonials" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselCustomerTestemonials" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselCustomerTestemonials" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselCustomerTestemonials" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselCustomerTestemonials" data-bs-slide-to="4" aria-label="Slide 5"></button>
                <button type="button" data-bs-target="#carouselCustomerTestemonials" data-bs-slide-to="5" aria-label="Slide 6"></button>
           </div>
        <div className="carousel-inner overflow-visible p-md-5 ">
            {/* Slide 1 */}
            <div className="carousel-item overflow-visible p-md-5 active">
                <div className="d-none d-md-flex gap-5 justify-content-around mb-5">
                    <TestemonialCard
                        title="Question text goes here?"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique."
                    />
                    <TestemonialCard
                        title="Question text goes here?"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique."
                    />
                </div>
                <div className="d-block d-md-none">
                    <TestemonialCard
                        title="Question text goes here?"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique."
                    />
                </div>
            </div>

            {/* Slide 2 */}
            <div className="carousel-item overflow-visible p-md-5">
                <div className="d-none d-md-flex gap-5 justify-content-around mb-5">
                    <TestemonialCard
                        title="Question text goes here?"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique."
                    />
                    <TestemonialCard
                        title="Question text goes here?"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique."
                    />
                </div>
                <div className="d-block d-md-none">
                    <TestemonialCard
                        title="Question text goes here?"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique."
                    />
                </div>
            </div>

            {/* Slide 3 */}
            <div className="carousel-item overflow-visible p-md-5">
                <div className="d-none d-md-flex gap-5 justify-content-around mb-5">
                    <TestemonialCard
                        title="Question text goes here?"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique."
                    />
                    <TestemonialCard
                        title="Question text goes here?"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique."
                    />
                </div>
                <div className="d-block d-md-none">
                    <TestemonialCard
                        title="Question text goes here?"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique."
                    />
                </div>
            </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default CustomerTestemonials;
