import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import girlCard from '../../assets/girl_card.svg';
import loveIt from '../../assets/love_it.svg';
import trueLove from '../../assets/true_love.svg';
import boostBusiness from '../../assets/boost_business.svg';

function CustomerBenefits() {
  const [activeImage, setActiveImage] = useState(girlCard);
  const [activeBox, setActiveBox] = useState(null);

  const handleMouseEnter = (image, box) => {
    setActiveImage(image);
    setActiveBox(box);
  };

  const isActive = (box) => activeBox === box ? 'border-warning border-5' : 'border-transparent border-5';

  return (
    <>
      {/* Desktop version */}
      <div className="d-none d-lg-flex row align-items-center mt-5 bg-white girl_box overflow-hidden">
        <div className="col-lg-6 d-flex justify-content-center p-4">
          <img className="img-fluid" src={activeImage} alt="Customer Benefit" />
        </div>
        <div className="col-lg-6 p-4 rounded-start rounded-bottom-lg border-bottom-lg">
          <div className="d-flex flex-column gap-5">
            <div
              className={`d-flex align-items-center gap-3 border-start ${isActive('box1')}`}
              onMouseEnter={() => handleMouseEnter(trueLove, 'box1')}
              style={{ paddingLeft: activeBox === 'box1' ? '0' : '0.1rem' }}
            >
              <div className="flex-grow-1 ms-2">
                <h2 className="text-dark fw-bold">
                  Digitize Loyalty Cards Today
                </h2>
                <p className="text-dark">
                  Our services allow customers to easily digitize their loyalty cards, eliminating the need for physical cards and ensuring hassle-free benefits collection. For business owners, this opens up a new marketing channel and provides efficient statistics on returning customers, enabling targeted outreach.
                </p>
              </div>
            </div>

            <div
              className={`d-flex align-items-center gap-3 border-start ${isActive('box2')}`}
              onMouseEnter={() => handleMouseEnter(girlCard, 'box2')}
              style={{ paddingLeft: activeBox === 'box2' ? '0' : '0.1rem' }}
            >
              <div className="flex-grow-1 ms-2">
                <h2 className="text-dark fw-bold">
                  Unlock Exclusive Rewards
                </h2>
                <p className="text-dark">
                  By digitizing your loyalty cards, you gain access to exclusive rewards and personalized offers. Say goodbye to carrying around multiple cards and hello to a seamless loyalty experience.
                </p>
              </div>
            </div>

            <div
              className={`d-flex align-items-center gap-3 border-start ${isActive('box3')}`}
              onMouseEnter={() => handleMouseEnter(boostBusiness, 'box3')}
              style={{ paddingLeft: activeBox === 'box3' ? '0' : '0.1rem' }}
            >
              <div className="flex-grow-1 ms-2">
                <h2 className="text-dark fw-bold">
                  Boost Your Business
                </h2>
                <p className="text-dark">
                  As a business owner, our digitization services provide you with a powerful tool to reach out to customers. Leverage our platform to engage with your audience, increase customer loyalty, and drive sales.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile version */}
      <div className="d-lg-none mt-5 row gx-0 pt-5 pb-5 justify-content-center grey_background">
        <div className="col-10 white_box loremCard " >
        <Carousel>
          <Carousel.Item>
            <img className="d-block img-fluid p-5" style={{ maxHeight: '100%', maxWidth: '100%' }} src={trueLove} alt="Digitize Loyalty Cards Today" />
            <div className="p-3">
              <h5>Digitalize Loyalty Cards Today</h5>
              <p>
                Our services allow customers to easily digitize their loyalty cards, eliminating the need for physical cards and ensuring hassle-free benefits collection. For business owners, this opens up a new marketing channel and provides efficient statistics on returning customers, enabling targeted outreach.
              </p>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img className="d-block img-fluid  p-5"  style={{ maxHeight: '100%', maxWidth: '100%' }} src={girlCard} alt="Unlock Exclusive Rewards" />
            <div className="p-3">
              <h5>Unlock Exclusive Rewards</h5>
              <p>
                By digitizing your loyalty cards, you gain access to exclusive rewards and personalized offers. Say goodbye to carrying around multiple cards and hello to a seamless loyalty experience.
              </p>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img className="d-block img-fluid  p-5" style={{ maxHeight: '100%', maxWidth: '100%' }} src={boostBusiness} alt="Boost Your Business" />
            <div className="p-3">
              <h5>Boost Your Business</h5>
              <p>
                As a business owner, our digitization services provide you with a powerful tool to reach out to customers. Leverage our platform to engage with your audience, increase customer loyalty, and drive sales.
              </p>
            </div>
          </Carousel.Item>
        </Carousel>
        </div>
      </div>
    </>
  );
}

export default CustomerBenefits;
