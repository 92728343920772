import React, { Component } from "react";
import MainMenuItemList from "./main-menu-item-list";
import RightMenuItemList from "./right-menu-item-list";


export default class MainMenuList extends Component {
    render() {
        const path = this.props.path;
        return (
            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <MainMenuItemList cssClass="nav navbar-nav" path={path} />
                <RightMenuItemList cssClass="nav navbar-nav ms-auto" path={path} />
            </div>
        )
    }
}