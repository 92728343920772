import * as actions from "../actions/index"

const INITIAL_STATE = {
    'general': {
     'name': '',
    'color-theme': 'Blue',
        'email': '',
        'picture': ''
    }
   ,
    'cardtemplate': {
        '_id': null,
        'backgroundcolor': '#5e72e4',
        'logo': "",
        'max_nr_stamps': "",
        'dig_pass_title': ""
    },
    mstemplate: {
        ms_title: null,
        ms_header: null,
    }, 
    src_img: null,
    crop: {
        unit: 'p',
        x: 0,
        y: 0,
        width: 50,
        height: 50

    },
    croppedImageUrl: null,
    croppedMsHeaderUrl: null,
    circularCrop: null,
    showModal: false
    
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case `${actions.SETTINGS_LOADED}_PENDING`:
            return { ...state }
            break;
        case `${actions.SETTINGS_LOADED}_FULFILLED`:
         
            let new_state =  {
                ...state,
                general: action.payload.data.general,
                cardtemplate: action.payload.data.cardtemplate,
                mstemplate: action.payload.data.mstemplate,
                croppedImageUrl: action.payload.data.cardtemplate.logo,
                croppedMsHeaderUrl: action.payload.data.mstemplate.ms_header,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
           
            return new_state;
            break;
        case `${actions.SETTINGS_LOADED}_REJECTED`:
            return {
                ...state,
                src_img: action.payload
            }
            break;

        case `${actions.SETTINGS_SAVED}_PENDING`:
          
            return { ...state }
            break;

        case `${actions.SETTINGS_SAVED}_FULFILLED`:
          
            return {
                ...state,
                cardTemplate: action.payload.data,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${actions.SETTINGS_SAVED}_REJECTED`:
          
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        case `${actions.SETTINGS_LOGO_SELECTED}`:
           
            return {
                ...state,
                src_img: action.payload,
                showModal: true,
                circularCrop: true
            }
            break;
        case `${actions.SETTINGS_MS_HEADER_SELECTED}`:

            return {
                ...state,
                src_img: action.payload,
                showModal: true,
                circularCrop: false

            }
            break;
        case `${actions.SETTINGS_CROP_SET}`:
           
            return {
                ...state,
                crop: action.payload,

            }
            break;
        case `${actions.SETTINGS_CROP_IMAGE_SET}`:
           
            return {
                ...state,
                croppedImageUrl: action.payload,

            }
            break;
        case `${actions.SETTINGS_CROP_MS_HEADER_SET}`:

            return {
                ...state,
                croppedMsHeaderUrl: action.payload,

            }
            break;
        case `${actions.SETTINGS_CROP_IMAGE_UPLOADED}_PENDING`:
            return { ...state };
            break;
        case `${actions.SETTINGS_CROP_IMAGE_UPLOADED}_FULFILLED`:
            let cropImageUrl;
            let cropMsHeaderUrl;
            if (state.circularCrop) {
                cropImageUrl = action.payload.data.img_url;
                cropMsHeaderUrl = state.croppedMsHeaderUrl;
            }
            else {

                cropImageUrl = state.croppedImageUrl;
                cropMsHeaderUrl = action.payload.data.img_url;

            }

            return {
                ...state,
                src_img: null,
                showModal: false,
                croppedImageUrl: cropImageUrl,
                croppedMsHeaderUrl: cropMsHeaderUrl,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${actions.SETTINGS_CROP_IMAGE_UPLOADED}_REJECTED`:
            
            console.log("rejected, for some reason:");
            console.log(action.payload);
            console.log("payload done ");
            return {
                ...state,
                message: action.payload,
                status: action.payload,
                statusClass: 'error'
            }
            break;
        default:
           
         
            return state;
    }
}
