import * as actions from "../actions/index"

const INITIAL_STATE = {
    currentCardID: null,
    card: null,
    message: '',
    status: 0
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
       
        case `${actions.SCAN_PASS_DISPLAYED}_PENDING`:
            return { ...state }
            break;
        case `${actions.SCAN_PASS_DISPLAYED}_FULFILLED`:
         
            return {
                ...state,
                card: action.payload.data,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${actions.SCAN_PASS_DISPLAYED}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;

        case `${actions.SCAN_STAMP_ADDED}_PENDING`:
            console.log("pending stamp...");
            return { ...state }
            break;

        case `${actions.SCAN_STAMP_ADDED}_FULFILLED`:
            console.log("stamp successfully added: ");
            console.log(action.payload);
            return {
                ...state,
                card: action.payload.data,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${actions.SCAN_STAMP_ADDED}_REJECTED`:
            console.log("rejected stamp");
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;

        case `${actions.SCAN_CARD_USED}_PENDING`:
            console.log("pending stamp...");
            return { ...state }
            break;

        case `${actions.SCAN_CARD_USED}_FULFILLED`:
            console.log("stamp successfully added: ");
            console.log(action.payload);
            return {
                ...state,
                card: action.payload.data,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${actions.SCAN_CARD_USED}_REJECTED`:
            console.log("rejected stamp");
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;

        default:
            return state;
    }
}

