import React, { Component } from "react";
import MainMenuList from "./main-menu-list";
import MenuToggle from "./menu-toggle";
import PropTypes from "prop-types";


export default class MainNavbar extends Component {
    // static contextTypes = {
    //     router: PropTypes.object
    // }
    render() {
        const path = this.context.router.route.location.pathname;
        return (
            
                <nav className="navbar navbar-expand-lg navbar-light bg-white">
                    <MenuToggle text="Stampedo" />
                    <MainMenuList path={path} />
                    
                </nav>
            
        )
    }
}
MainNavbar.contextTypes = {
    router: PropTypes.object
}