import React, { Component } from 'react';
import Plus from '../../assets/Plus.svg';
import Minus from '../../assets/minus.svg'


export default class FaqCard extends Component {
    constructor(props) {
        super(props);

    }
     state = {
        detailsVisible: false
    };
     toggleVisible = () => {
         this.setState({ detailsVisible: !this.state.detailsVisible });
   };
    render() {
    return (
        <div className=" mb-4 faqCard p-2 ">
                <div className="d-flex justify-content-between align-items-center">
                   
                        <div className=" h6 fw-bold text-start font_fam">
                            {this.props.title}
                      
                    </div>
                  <img className="img-fluid " src={this.state.detailsVisible ?Minus: Plus} onClick={this.toggleVisible.bind(this)} alt="icon " />
                   </div>
                <p className={`text-start mt-4 fs-5 ${this.state.detailsVisible ? "" : "d-none"}`}>
                    {this.props.description}
                </p>
          
           
        </div>


  );

  }
  }