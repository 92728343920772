import {
    FETCH_SUPPLIER_LIST,
    SUPPLIER_DETAIL,
    SUPPLIER_DELETE,
    SUPPLIER_UPDATE,
    SUPPLIER_CREATE
} from "../actions/index"

const INITIAL_STATE = {
    suppliers: [],
    supplier: null,
    message: '',
    status: 0,
    statusClass: ''
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${FETCH_SUPPLIER_LIST}_PENDING`:
            return { ...state };
            break;
        case `${FETCH_SUPPLIER_LIST}_FULFILLED`:
            return {
                ...state,
                suppliers: action.payload.data,
                supplier: null,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${FETCH_SUPPLIER_LIST}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        case `${SUPPLIER_DETAIL}_PENDING`:
            return { ...state };
        case `${SUPPLIER_DETAIL}_FULFILLED`:
            return {
                ...state,
                supplier: action.payload.data
            }
        case `${SUPPLIER_DETAIL}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        case `${SUPPLIER_CREATE}_PENDING`:
            return { ...state };
            break;
        case `${SUPPLIER_CREATE}_FULFILLED`:
            return {
                ...state,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${SUPPLIER_CREATE}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        case `${SUPPLIER_UPDATE}_PENDING`:
            return { ...state };
            break;
        case `${SUPPLIER_UPDATE}_FULFILLED`:
            return {
                ...state,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${SUPPLIER_UPDATE}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        case `${SUPPLIER_DELETE}_PENDING`:
            return { ...state };
        case `${SUPPLIER_DELETE}_FULFILLED`:
            return {
                ...state,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
        case `${SUPPLIER_DELETE}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        default:
            return state;
    }

}