import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Navbar from "./navbar/navbar";
import SupplierList from "./supplier/supplier_list";
import Cards from "./card/cards";
import Print from "./print/print";
import Scan from "./scan/scan";
import Settings from "./settings/settings";
import GetGooglePass from "./gen_pass/gen_pass"
import CardDetail from "./card/card-detail";
import PropTypes from "prop-types";

export default class App extends Component {
   
    componentWillMount() {
      

    }
  render() {
    const supplierId = this.props.match.params.supplierId === undefined ? 0 : this.props.match.params.supplierId;
    return (
      <div className="container">
        <Navbar />
            <div>
               
          <Route path="/web/cards/:supplierId?/:page?" component={Cards} />
          <Route path="/web/print" component={Print} />
                <Route path="/web/scan" component={Scan} />
                <Route path="/web/settings" component={Settings} />
          <Route path="/web/carddetail/:cardId" component={CardDetail} />
         
        </div>
      </div>
    );
  }
}

App.contextTypes = {
    router: PropTypes.object
}