import React, { Component } from "react";
import StampedoLogo from '../../assets/gpt_logo.png';

export default class MenuToggle extends Component {
    render() {
        return (
            <div className="navbar-brand d-flex justify-content-between align-items-center ms-auto">
                  <a className="navbar-brand align-items-center h1 p-2" >
                    <img className="me-1" src={StampedoLogo} width="30px" height="30px" />
                    {this.props.text}
                </a>
                <button type="button" className="navbar-toggler collapsed align-items-center" data-bs-toggle="collapse" data-bs-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="navbar-toggler-icon"></span>
                 </button>
              
            </div>
        )
    }
}