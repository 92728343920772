import React, { Component } from "react";
import { fetchSuppliers, updateCard, cardDetail } from "../../actions/index";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import PropTypes from "prop-types"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import showAlertBox from "../../tools/tools"

const validate = values => {
    const errors = {}
    const requiredFields = ['cardName', 'maxStamps', 'currentStamps', 'supplierId', 'used']
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    return errors
}

const renderTextField = ({ input, label, type, defaultValue, name, id, meta: { touched, error }, ...custom }) => (

    <TextField hintText={label}
        floatingLabelText={label}
        className="text-field"
        defaultValue={defaultValue}
        type={type}
        id={id}
        onChange={(event) => input.onChange(event.target.value)}
        onBlur={(event) => input.onBlur(event.target.value)}
        errorText={touched && error}
        {...custom}
    />
)

const renderSelectField = ({ input, label, defaultValue, meta: { touched, error }, children, ...custom }) => (

    <SelectField
        floatingLabelText={label}
        className="select-field"
        errorText={touched && error}
        {...input}
        //value={defaultValue}
        onChange={(event, index, value) => input.onChange(value)}
        children={children}
        {...custom} />

)


class CardUpdateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
            cardName: '',
            maxStamps: 0,
            currentStamps: 0,
            supplierId: 0,
            used: false
        };
    }
    toggleBlocking(status) {
        this.setState({ blocking: status });
    }
    componentWillMount() {
        this.setState({
            used: false
        });
        this.props.fetchSuppliers();
        var cardId = this.props.cardId;
        if (cardId === '0' && cardId === undefined) {
            this.context.router.history.push("/admin/cards")
        }
        this.props.cardDetail(cardId);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.status === 204) {
            this.toggleBlocking(false)
            showAlertBox("Card was updated successfully", "alert-success");
            this.context.router.history.replace("/admin/cards")
        }
        else if (nextProps.status === 400) {
            this.toggleBlocking(false)
            showAlertBox(nextProps.message, "alert-danger");
        }
        if (nextProps.card !== this.props.card) {
            this.setState({
                cardName: nextProps.card.cardName,
                unitPrice: nextProps.card.maxStamps,
                unitsInStock: nextProps.card.currentStamps,
                supplierId: nextProps.card.supplierId,
                used: true
            });
        }
    }
    onSubmit(props) {
        this.toggleBlocking(true)
        this.props.updateCard(props);
    }
    renderSupplierItem() {
        return this.props.suppliers.map((supplier) => {
            return (
                <MenuItem key={supplier._id} value={supplier._id} primaryText={supplier.supplierName} />
            );
        })
    }

    render() {
        if (!this.state.done) {
            return (
                <div>Loading...</div>
            )
        }
        const { handleSubmit } = this.props
        //const { handleSubmit, pristine, reset, submitting } = this.props
        return (
            <div className="col-md-12 col-sm-12">
                <BlockUi tag="div" blocking={this.state.blocking}>
                    <div className="page-header">
                        <h4 className="text-primary"> Update card </h4>
                    </div>
                    <div className="container">
                        <MuiThemeProvider muiTheme={getMuiTheme()}>
                            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                <div className="form-group">
                                    <Field type="text" name="cardName" id="cardName" defaultValue={this.state.cardName} component={renderTextField} label="Card Name" />
                                </div>
                                <div>
                                    <Field type="number" name="maxStamps" defaultValue={this.state.maxStamps} component={renderTextField} label="Max Stamps" />
                                </div>
                                <div>
                                    <Field type="number" name="currentStamps" defaultValue={this.state.currentStamps} component={renderTextField} label="Current Stamps" />
                                </div>
                                <div>
                                    <Field name="supplierId" defaultValue={this.state.supplierId} component={renderSelectField} label="Supplier">
                                        {this.renderSupplierItem()}
                                    </Field>
                                </div>
                                <div>
                                    <Field name="used" defaultValue={this.state.used} component={renderTextField} label="Used" />
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                    <Link to="/admin/cards" className="btn btn-default">Go Back</Link>
                                </div>
                            </form>
                        </MuiThemeProvider>
                    </div>
                </BlockUi>
            </div>
        )
    }
}


function mapStateToProps(state) {
    //console.log(state.adminCards);
    return {
        suppliers: state.suppliers.suppliers,
        card: state.cards.card,
        initialValues: state.cards.card,
        message: state.cards.message,
        status: state.cards.status,
        statusClass: state.cards.statusClass
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchSuppliers: fetchSuppliers,
        updateCard: updateCard,
        cardDetail: cardDetail
    }, dispatch);
}

CardUpdateForm.contextTypes = {
    router: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'CardUpdate',
    enableReinitialize: true,
    validate
})(CardUpdateForm));

