import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import MainNavbar from "./navbar/main-navbar";
import HomePage from "./public/home";
import ThankYouPage from "./public/thank_you_reg";
import Print from "./print/print";
import Scan from "./scan/scan";
import Settings from "./settings/settings";
import GetGooglePass from "./gen_pass/gen_pass"
import CardDetail from "./card/card-detail";
import PropTypes from "prop-types";

export default class PublicPage extends Component {

    componentWillMount() {


    }
    render() {
        const supplierId = this.props.match.params.supplierId === undefined ? 0 : this.props.match.params.supplierId;
        return (
            <div className="container  gx-0" >
            
                    <MainNavbar/>
                    <Route path="/public" exact="true" component={HomePage} />
                    <Route path="/public/thankyou" component={ThankYouPage} />
                    <Route path="/public/gengooglepass" component={GetGooglePass} />
            
            </div>
        );
    }
}

