import React from "react";

export default function LongCard(props) {
    return (
        <div className="card mb-4 loremCard border-0">
            <div className="card-body p-4">
                <div className="row align-items-center">
                    <div className="col-2 col-md-12 text-start opacity-25 mb-sm-4">
                        <img src={props.icon} alt="icon" />
                    </div>
                    <div className="col-10 col-md-12">
                        <h4 className="card-title h1 text-start fw-bold">
                            {props.title}
                        </h4>
                    </div>
                </div>
                <p className="card-text text-start mt-4 fs-5">
                    {props.description}
                </p>
                <div className="row mt-4">
                    <div className="col-12 col-md-4">
                        <button className="container-fluid btn btn-yellow align-start rounded-5 text-uppercase px-3 py-2">
                            {props.btnName}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}