import React, { Component } from 'react';
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import QRCode from "react-qr-code";
import { QrReader } from 'react-qr-reader';
import { cardIdScanned, stampAdded, cardUsed } from "../../actions/index"
import { displayScannedCard } from "../../actions/index"


// import PropTypes from "prop-types"

class Scan extends Component {
    useCoupon = () => {
       
        this.props.cardUsed(this.props.card);
    }
    handleUpdate = () => {

        this.props.stampAdded(this.props.card);
    }
    render = () =>  {
        return this.renderCard();
    };
    renderCard = () => {
       
        
        if (this.props.card == undefined)
            return (
                <div>
                    <div className="alert alert-warning">
                        Please Scan the Digital Wallet Card
                    </div>
                    <QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                // this.props.cardIdScanned();
                                this.props.displayScannedCard(result?.text);

                            }

                            if (!!error) {
                                console.log(error);
                            }
                        }}
                        containerStyle={{ width: '30%' }}
                        viewFinder={()=>{return (<div> This is my finder</div>)}}
                    />
                    <p>{"data is static: " + this.props.cardID}</p>
                </div>
            );
        else
            if (this.props.card.currentStamps < this.props.card.maxStamps)
               return (
            <div>
            <p> The card Id: </p>
            <p>{this.props.card._id}</p>
                <p> The card current stamps: </p>
                       <p>{this.props.card.currentStamps}</p>
                       <p>
                       <button className="btn btn-sm btn-primary pull-left" onClick={this.handleUpdate}>
                           <i className="glyphicon glyphicon-plus"></i>
                               Add Stamp</button>
                           <br/></p>
                <p> The card max stamps: </p>
                <p>{this.props.card.maxStamps}</p>

                    </div>
               );
            else
                if(this.props.card.used == false)
                return (
                    <div>
                        <p> The card Id: </p>
                        <p>{this.props.card._id}</p>
                        <p> The card current stamps: </p>
                        <p>{this.props.card.currentStamps}</p>
                       
                        <p> The card max stamps: </p>
                        <p>{this.props.card.maxStamps}</p>
                        <button className="btn btn-sm btn-primary pull-left" onClick={this.useCoupon}>
                            Use Coupon</button>
                    </div>
                    );
                else
                    return (
                        <div>
                            <p> The card Id: </p>
                            <p>{this.props.card._id}</p>
                            <p> The card current stamps: </p>
                            <p>{this.props.card.currentStamps}</p>

                            <p> The card max stamps: </p>
                            <p>{this.props.card.maxStamps}</p>
                            <p className="alert alert-success">This Card is used!</p>
                        </div>
                    );

            /* 
            <div>
                <span id="supplierId">{card._id}</span>
                <div className={displayClass} style={paddingBottom} id="maxStampsBox">
                    <h6 className="stamps-attr">
                        <small>Max Stamps</small>
                        <span className="pull-right">
                            {card.maxStamps}
                        </span>
                    </h6>
                </div>
                <div className={displayClass} style={paddingBottom} id="currentStampsBox">
                    <h6 className="stamps-attr">
                        <small>Current Stamps</small>
                        <span className="pull-right">
                            {card.currentStamps}
                        </span>
                    </h6>
                </div>
                <div
                    className={displayClass2}
                    style={paddingBottom}
                    id="usedBox"
                >
                    <span style={marginRight} aria-hidden="true" />
                    The card is used
                </div>


                <div>

                </div>
            </div>
            */
     
    }
}


function mapStateToProps(state) {
    return {
        cardID: state.scan.currentCardID,
        card: state.scan.card
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        cardIdScanned: cardIdScanned,
        displayScannedCard: displayScannedCard,
        stampAdded: stampAdded,
        cardUsed: cardUsed

        // callMessage: callMessage 
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Scan)
