import React, { Component } from "react"
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { fetchCards } from "../../actions/index"
import Paging from "../paging"

class CardList extends Component {

    componentWillMount() {
        const supplierId = this.props.supplierId;
        const page = this.props.page;
        this.props.fetchCards(supplierId, page);
    }
    
    componentWillReceiveProps(nextProps) {

        const supplierId = nextProps.supplierId;
        const page = nextProps.page;

        if (this.props.supplierId !== supplierId || this.props.page !== page) {
            this.props.fetchCards(supplierId, page);

        }
    }
    renderCards() {
        return this.props.cardList.map((card) => {
            return (
                <div key={card._id} className="col-sm-3 col-md-3 card-list">
                    <div className="thumbnail">
                        <img src="/images/card.jpg" alt="..." />
                        <div className="caption">
                            <div className="card-title">{card.cardName}</div>
                            <div>
                                <span className="pull-left">
                                    {card.maxStamps}
                                </span>
                                <span className="pull-right">
                                    {card.currentStamps}
                                </span>
                                <span className="pull-left">
                                    {card.used}
                                </span>
                                <Link to={"/web/carddetail/"+card._id} className="btn btn-xs btn-success pull-right">Go to Detail</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        })
    }
    render() {
        if (this.props.cardList.length === 0) {
            return (
                <div>Loading...</div>
            )
        }

        return (
            <div className="col-md-10 col-sm-10">
                <div className="row">
                    {this.renderCards()}
                </div>
                <Paging url="/web/cards"
                    type="cards"
                    PageSize={this.props.PageSize}
                    PageCount={this.props.PageCount}
                    CurrentSupplier={this.props.supplierId}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cardList: state.cards.cards,
        PageSize: state.cards.PageSize,
        PageCount: state.cards.PageCount
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCards: fetchCards,
        // callMessage: callMessage 
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CardList)
