import React, { Component } from "react"
import { Link } from "react-router-dom";

export default class MenuItem extends Component {
    render() {
        return(
            <li className="nav-item"
                style={{ color: '#31303D', fontSize: 16, fontFamily: 'Space Grotesk', fontWeight: '400', textTransform: 'uppercase', wordWrap: 'break-word' }}
                    
            >
                
                <Link className={this.props.cssClass} to={this.props.url}>{this.props.text}</Link>
            </li>
        );
    }
    
}

