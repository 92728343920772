import React, { Component } from 'react';
import AdminCardList from "./admin-card-list"

export default class AdminCards extends Component {
  render() {
    const cardId = this.props.match.params.cardId === undefined ? 0 : this.props.match.params.cardId;
    const page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page
    console.log(cardId);
    return (
      <AdminCardList page={page} cardId={cardId} />
    );
  }
}
