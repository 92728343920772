import React from "react";

export default function NormalCard(props) {
    return (
        <div className="card mb-4 loremCard border-0">
            <div className="card-body p-4">
                <div className="row">
                    <div className="col-2 col-md-12 text-start opacity-25 mb-sm-4">
                        <img src={props.icon} alt="icon" />
                    </div>
                    <div className="col-10 col-md-12">
                        <h4 className="card-title h3 text-start fw-bold">
                            {props.title}
                        </h4>
                    </div>
                </div>
                <p className="card-text h7 text-start text-break fs-5">
                    {props.description}
                </p>
                <div className="text-dark text-start">
                    <a className="text-dark text-uppercase fs-6" href="/">{props.btnName}</a>{"   >"}
                </div>
            </div>
        </div>
    )
}