import React, { Component } from "react"
import { connect } from "react-redux"
import SupplierItem from "./supplier_item"
import { fetchSuppliers } from "../../actions/index"


class SupplierList extends Component {
    componentWillMount() {
        this.props.fetchSuppliers();
    }
    renderSupplierItem() {
        return this.props.suppliers.map((supplier) => {
            return (
                <SupplierItem key={supplier._id} supplierName={supplier.supplierName} supId={supplier._id} currentSupplier={this.props.supplierId} />
            );
        });
    }
    render() {
        if (this.props.suppliers === 0) {
            return (
                <div>Loading...</div>
            )
        }
        return (
            <div className="col-md-2 col-sm-2">
                <div className="list-group">
                    {this.renderSupplierItem()}
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return { suppliers: state.suppliers.suppliers }
}

export default connect(mapStateToProps, { fetchSuppliers })(SupplierList)