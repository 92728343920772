import { combineReducers } from 'redux';
import Suppliers from "./reducer-suppliers";
import Cards from "./reducer-cards";
import Pass from "./reducer-pass";
import Scan from "./reducer-scan";
import Settings from "./reducer-settings";
import AccessToken from "./reducer-token";
import { reducer as formReducer } from 'redux-form';


const rootReducer = combineReducers({
  suppliers: Suppliers,
  cards: Cards,
  accessToken: AccessToken,
  form: formReducer,
  pass: Pass,
  scan: Scan,
  settings: Settings

});

export default rootReducer;