
function BenefitText(props){
    return (

        <div className="d-flex justify-content-start ">
            <i className="bi bi-check text-success"></i>
            <p className="card-text text-start ms-4">
                               {props.content} 
           </p>
        </div>

    )






}

export default function PaymentCard(props) {
    return (
        <div className="mb-4 ms-2 me-2 loremCard">
            <div className="row gx-0 justify-content-center "> 
                <div className="col-md-8 col-12">
                    <h4 className="text-uppercase text-decoration-underline mt-3"> {props.title }</h4>
                <p>{props.description}</p>
                </div>
                <div className="col-md-6 col-10 ms-1 me-1 mb-2 border-bottom border-2">
                    <h2> ${props.price }/Mt </h2>
                </div>

            </div>
            <div className="card-body p-4">
                <div className="row gx-0 align-items-center">
                    <div className="col-12 text-start d-none d-md-block ">
                       Includes:
                    </div>
                    <div className="col-12 ms-4">
                        <BenefitText content={props.benefit_1}/>
                        <BenefitText content={props.benefit_2} />
                        <BenefitText content={props.benefit_3} />
                        <BenefitText content={props.benefit_4} />
                     </div>
                </div>
                
                
            </div>
            <div className="row  gx-0 mt-4 justify-content-center pb-3">
                    <div className="col-10 ms-1 me-1">
                        <button className="container-fluid btn btn-yellow align-center rounded-5 text-uppercase     ">
                           start 6 months free trial 
                        </button>
                    </div>
                </div>
        </div>


  );

  }