import React, { Component } from "react";
import { Redirect } from 'react-router-dom'
import LogoutButton from './logout_btn'
import { logout } from "../actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ProtectedRoute extends Component {
    constructor(props) {
        super(props)
       
    }
    exprireTimeControl() {
        var hours = 10; // Reset when storage is more than 24hours
        var now = new Date().getTime();
        var setupTime = localStorage.getItem('setupTime');

        if (now - setupTime > hours * 60 * 60 * 1000) {
            this.props.logout();
        }
    }

    logoutHandler = () => {
    
        //event.preventDefault();
       

    }

    componentWillMount() {
        this.exprireTimeControl();
    }
    getDefaultPageForRole = () => {
        console.log("role is: ");
        console.log(this.props.role);
        
        switch (this.props.role) {
            case "admin":
                return "/admin";
                break;
            case "user":
                return "/web";
                break;
            default:
                return "/public";

        }
    }
    render() {
        if (!this.props.accessToken) {
            // return <Redirect to="/login" state={{ from: location }} replace />
            //   this.context.router.history.push("/login")
            //  this.context.router.history.replace("/login")

            return (<Redirect to={{
                pathname: "/login",
                state: { fromPath: this.props.location.pathname }
            }} />);
        }
        else {
            if (!(!this.props.roles.length || this.props.roles.includes(this.props.role))) {

                return (<Redirect to={{
                    pathname: this.getDefaultPageForRole(),
                    state: { fromPath: this.props.location.pathname }
                }} />);



            }
            else
                return (<div> <LogoutButton /> {this.props.childComponent} </div>)
        }
    }




    }



function mapStateToProps(state) {
    return {
        accessToken: state.accessToken.accessToken,
        role: state.accessToken.role,
        message: state.accessToken.message,
        status: state.accessToken.status,
        statusClass: state.accessToken.statusClass
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
       logout: logout
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);



/*
= ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            fakeAuth.isAuthenticated === true ? (
                <Component {...props} />
            ) : (
                <Redirect to="/login" />
            )
        }
    />
);
*/