import React, { Component } from "react";
import { logout } from "../actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Define the logout button component
class LogoutButton extends React.Component {
    constructor(props) {
        super(props);
      
     
    }

    
    
    render() {
        return (
            <button
                id="logout-button"
                onClick={this.props.logout}
                
            >
                Log out
            </button>
        );
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout: logout
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(LogoutButton);