import {
    FETCH_CARD_LIST,
    FETCH_ADMIN_CARD_LIST,
    CARD_DETAIL,
    CARD_CREATE,
    CARD_UPDATE,
    CARD_DELETE,
} from "../actions/index"

const INITIAL_STATE = {
    cards: [],
    adminCards: [],
    PageCount: 0,
    PageSize: 0,
    card: null,
    message: '',
    status: 0,
    statusClass: ''
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${FETCH_CARD_LIST}_PENDING`:
            return { ...state }
            break;
        case `${FETCH_CARD_LIST}_FULFILLED`:
        console.log(action.payload.data.cards)
            return {
                ...state, cards: action.payload.data.cards,
                PageSize: action.payload.data.pageSize,
                PageCount: action.payload.data.pageCount,
                card: null,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${FETCH_CARD_LIST}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        case `${FETCH_ADMIN_CARD_LIST}_PENDING`:
            return { ...state }
            break;
        case `${FETCH_ADMIN_CARD_LIST}_FULFILLED`:
        console.log(action.payload)
            return {
                ...state, cards: action.payload.data.cards,
                PageCount: action.payload.data.pageCount,
                PageSize: action.payload.data.pageSize,
                card: null,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
        case `${FETCH_ADMIN_CARD_LIST}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        case `${CARD_DETAIL}_PENDING`:
            return { ...state }
            break;
        case `${CARD_DETAIL}_FULFILLED`:
        console.log(action.payload.data);
            return {
                ...state,
                card: action.payload.data,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${CARD_DETAIL}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;

        case `${CARD_CREATE}_PENDING`:
            return { ...state };
            break;
        case `${CARD_CREATE}_FULFILLED`:
            return {
                ...state,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${CARD_CREATE}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        case `${CARD_UPDATE}_PENDING`:
            return { ...state };
            break;
        case `${CARD_UPDATE}_FULFILLED`:
            return {
                ...state,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
            break;
        case `${CARD_UPDATE}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;
        case `${CARD_DELETE}_PENDING`:
            return { ...state };
        case `${CARD_DELETE}_FULFILLED`:
            return {
                ...state,
                message: action.payload.statusText,
                status: action.payload.status,
                statusClass: 'ok'
            }
        case `${CARD_DELETE}_REJECTED`:
            return {
                ...state,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;

        default:
            return state;
    }
}
