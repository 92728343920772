import './bb.css';
import LogoCard from './LogoCard';
import CustomerLogo from '../../assets/customer_logo.svg';
import Starbucks from '../../assets/starbucks.webp';
import Canon from '../../assets/Canon-logo.webp';
import CaffeeLogo from '../../assets/caffee_logo.png';
import LoremLogo from '../../assets/example-logo-2.jpg';
import Carousel from 'react-bootstrap/Carousel';

function CustomerLogos() {
  return (
    <div className="text-center text-dark container mt-5 white_box mb-5">
      <div className='h6 fw-bold'>Used by the world's most average companies</div>
      
      {/* Carousel for mobile devices */}
      <div className="d-md-none mt-5">
        <Carousel>
          <Carousel.Item>
            <LogoCard icon={CaffeeLogo} />
          </Carousel.Item>
          <Carousel.Item>
            <LogoCard icon={LoremLogo} />
          </Carousel.Item>
          <Carousel.Item>
            <LogoCard icon={CaffeeLogo} />
          </Carousel.Item>
          <Carousel.Item>
            <LogoCard icon={LoremLogo} />
          </Carousel.Item>
        </Carousel>
      </div>

      {/* Grid for larger screens */}
      <div className="row mt-5 d-none d-md-flex">
        <LogoCard icon={CaffeeLogo}/> 
        <LogoCard icon={LoremLogo}/> 
        <LogoCard icon={CaffeeLogo}/> 
        <LogoCard icon={LoremLogo}/>
      </div>
    </div>
  );
}

export default CustomerLogos;