import React, { Component } from "react"
import CardUpdateForm from "./card-update"
import CardInsertForm from "./card-insert"

export default class CardForm extends Component {

    render() {
        const cardId = this.props.match.params.cardId
        if (cardId !== undefined) {
            return (
              <CardUpdateForm cardId={cardId} />
            )
        }
        return (
            <CardInsertForm />
        )
    }
}