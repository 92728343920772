import React, { Component } from "react";
import MenuItem from "./menu-item"

export default class MenuItemList extends Component {
    render() {
        const path = this.props.path;
        return (
            <ul className={this.props.cssClass}>
                <MenuItem text="Home" cssClass='nav-link {path === "/web" ? "active" : ""}' url="/web" />
                <MenuItem text="Dashboard" cssClass='nav-link {path === "/web/cards" ? "active" : ""}' url="/web/cards" />
                <MenuItem text="Print" cssClass='nav-link {path === "/web/print" ? "active" : ""}' url="/web/print" />
                <MenuItem text="Scan" cssClass='nav-link {path === "/web/scan" ? "active" : ""}' url="/web/scan" />
                <MenuItem text="Settings" cssClass='nav-link {path === "/web/settings" ? "active" : ""}' url="/web/settings" />
            </ul>
        )
    }
}