import './bb.css';
import digitalLoyalty from '../../assets/digital_loyalty.svg'
import digitalLoyalty_mob from '../../assets/digital_loyalty_mob.svg'

import LongCard from './LongCard';
import NormalCard from './NormalCard';
import MedalIcon from '../../assets/medalIcon.svg';
import VectorIcon from '../../assets/Vector1.svg'
import VectorIcon2 from '../../assets/Vector2.svg'

function Hero() {
  return (

 <div className="row gx-0 align-items-center white_box mt-lg-5">
                <div className="col-lg-6  col-sm-12 col-md-12 p-4"  >
                        <div className=" h1 text-center text-md-start fw-bold">
                        Digitalize your loyalty cards and unlock benefits
                        </div>
                        <div className="card-text text-start mt-4 fs-5 d-none d-md-block">
                        Say goodbye to messy loyalty cards and enjoy the convenience of a digital platform. Sign up now and start collecting rewards hassle-free!
                        </div>
                        
                        <div className="d-flex justify-content-between mt-2 d-none d-lg-block">
                          
                                <button className="btn btn-yellow  rounded-5 text-uppercase align-self-center">
                                Sign Up
                                </button>
                                <a className="text-dark text-uppercase ms-5 fs-6 align-self-center" href="/">Learn More</a>{"   >"}
                            
                       </div>
                </div>
                <div className="col-lg-6  col-sm-12 col-md-12 ">

                    <img  className="img-fluid mx-auto d-none d-md-block" src={digitalLoyalty} alt="Digital Loyalty" />
                    <img  className="img-fluid mx-auto d-block d-md-none" src={digitalLoyalty_mob} alt="Digital Loyalty"  />
                </div>
                
                <div className="col-12 d-lg-none">
                        <button className="container-fluid btn btn-yellow align-start rounded-5 text-uppercase px-3 py-2">
                           Sign Up
                        </button>
                    </div>
                
                <div className="col-12 d-lg-none mt-2">
                        <button className="container-fluid btn btn-grey align-start rounded-5 text-uppercase px-3 py-2">
                          Learn More
                        </button>
                    </div>

            </div>
            
           
         

      
  );
}

export default Hero;