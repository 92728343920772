import React, { Component } from 'react';
import { Route } from 'react-router-dom'
import AdminNavbar from "./navbar/admin-navbar";
// import LoginForm from "./login_form";
import AdminCards from "./card/admin-cards";
import AdminSuppliers from "./supplier/admin-supplier-list";
import CardForm from "./card/card-form";
import SupplierForm from "./supplier/supplier-form";
import PropTypes from "prop-types";
// injectTapEventPlugin();


export default class AdminApp extends Component {
  
  render() {

    return (
      <div className="container">
        <AdminNavbar />
        <div className="col-md-12 col-sm-12">
          <div id="alertBox" className="alert" role="alert">
            This is a success alert—check it out!
         </div>
          <Route path="/admin/cards/:page?" component={AdminCards} />
          <Route path="/admin/card/cardform/:cardId?" component={CardForm} />
          <Route path="/admin/suppliers/:page?" component={AdminSuppliers} />
          <Route path="/admin/supplier/supplierform/:supplierId?" component={SupplierForm} />
        </div>
      </div>
    );
  }
}

AdminApp.contextTypes = {
  router: PropTypes.object
}


