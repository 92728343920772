import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import PaymentCard from './PaymentCard';
import './bb.css';

function PaymentPlans() {
  return (
    <div className="text-center text-dark container mt-5">
      <div className='fw-bold mb-2'>Invest in your business</div>
      <div className='h1 fw-bold'>Pricing Plan</div>
      <div className='subcaption mt-4 mb-5'>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet</div>

      {/* Carousel for mobile and desktop */}
      <Carousel
        controls={false}
        indicators={true}
        interval={null}
        className="d-lg-none carousel-indicators-dark"
      >
        <Carousel.Item>
          <PaymentCard
            title="Starter Plan"
            description="Perfect for small businesses"
            price="9.99"
            benefit_1 = "6 months free trial"
            benefit_2 = "Up to 2000 digital passes per year"
            benefit_3 = "Up to 200 paper cards per year"
            benefit_4 = "1 Broatcast message per Month"
          />
        </Carousel.Item>
        <Carousel.Item>
          <PaymentCard
                      title="Premium Plan"
                      description="Ideal for growing businesses"
                      price="19.99"
                      benefit_1="6 months free trial"
                      benefit_2="Unlimited digital passes per year"
                      benefit_3="500 paper cards per year"
                      benefit_4="4 Broatcast message per Month"
          />
        </Carousel.Item>
      </Carousel>

      {/* Grid layout for larger screens */}
      <div className="row justify-content-center mt-5 d-none d-lg-flex">
        <div className="col-lg-4 col-sm-12 col-md-6">
          <PaymentCard
                      title="Starter Plan"
                      description="Perfect for small businesses"
                      price="9.99"
                      benefit_1="6 months free trial"
                      benefit_2="Up to 2000 digital passes per year"
                      benefit_3="Up to 200 paper cards per year"
                      benefit_4="1 Broatcast message per Month"
          />
        </div>
        <div className="col-lg-4 col-sm-12 col-md-6">
          <PaymentCard
                      title="Premium Plan"
                      description="Ideal for growing businesses"
                      price="19.99"
                      benefit_1="6 months free trial"
                      benefit_2="Unlimited digital passes per year"
                      benefit_3="500 paper cards per year"
                      benefit_4="4 Broatcast message per Month"
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentPlans;
